export default {
  en: {
    'auth.title': 'Buy anything you want with Qempo',
    'auth.subtitle': 'Fast, Simple and Safe',
    'auth.login.title': 'Log in',
    'auth.login.terms.agree.first': 'I agree with the',
    'auth.login.terms.agree.terms': 'Terms and Conditions',
    'auth.login.terms.agree.and': 'and',
    'auth.login.terms.agree.privacy': 'Privacy Policies',
    'auth.login.terms.agree.last': 'of Qempo',
    'auth.facebook.login': 'Continue with Facebook',
    'auth.login.email': 'Email',
    'auth.login.email.error.required': 'Email is required',
    'auth.login.email.error.format': 'The email format is incorrect',
    'auth.login.email.error.inUse': 'The email account is already registered',
    'auth.login.password': 'Password',
    'auth.login.password.error.required': 'Password is required',
    'auth.login.password.error.min': 'La contraseña debe tener 8 caracteres o más',
    'auth.login.btn': 'Log in',
    'auth.login.error': 'Unexpected error',
    'auth.login.error.unauthorized': 'The information provided is invalid',
    'auth.login.register.account': "Don't have an account yet?",
    'auth.login.register.signup': 'Sign up',
    'auth.signup.firstName': 'First name',
    'auth.signup.firstName.error.required': 'First name is required',
    'auth.signup.lastName': 'Last name',
    'auth.signup.lastName.error.required': 'Last name is required',
    'auth.signup.password.confirm': 'Repeat the password',
    'auth.signup.password.confirm.error.same': 'The password must be the same',
    'auth.signup.title': 'Sign up',
    'auth.signup.btn': 'Sign up',
    'auth.signup.error': 'Unexpected error',
    'auth.signup.login.account': 'Already got an account?',
    'auth.signup.login': 'Log in here',
  },
  es: {
    'auth.title': 'Compra lo que quieres con viajero de Qempo',
    'auth.subtitle': 'Rápido, Simple y Seguro',
    'auth.login.title': 'Iniciar Sesión',
    'auth.login.terms.agree.first': 'Estoy de acuerdo con los',
    'auth.login.terms.agree.terms': 'Términos y Condiciones',
    'auth.login.terms.agree.and': 'y',
    'auth.login.terms.agree.privacy': 'Políticas de Privacidad',
    'auth.login.terms.agree.last': 'de Qempo',
    'auth.facebook.login': 'Continuar con Facebook',
    'auth.login.email': 'Correo electrónico',
    'auth.login.email.error.required': 'Debes ingresar un correo electrónico',
    'auth.login.email.error.format': 'El formato del correo electrónico es incorrecto',
    'auth.login.email.error.inUse': 'El correo electrónico ingresado ya está registrado',
    'auth.login.password': 'Contraseña',
    'auth.login.password.error.required': 'Contraseña obligatoria',
    'auth.login.password.error.min': 'La contraseña debe tener 8 caracteres o más',
    'auth.login.btn': 'Iniciar Sesión',
    'auth.login.error': 'Error al iniciar sesión',
    'auth.login.error.unauthorized': 'Los datos ingresados no son correctos',
    'auth.login.register.account': '¿No tienes una cuenta?',
    'auth.login.register.signup': 'Crear cuenta',
    'auth.signup.firstName': 'Nombres',
    'auth.signup.firstName.error.required': 'Debes ingresar tus nombres',
    'auth.signup.lastName': 'Apellidos',
    'auth.signup.lastName.error.required': 'Debes ingresar tus apellidos',
    'auth.signup.password.confirm': 'Repite tu contraseña',
    'auth.signup.password.confirm.error.same': 'Debes repetir tu contraseña',
    'auth.signup.title': 'Registro',
    'auth.signup.btn': 'Registrarme',
    'auth.signup.error': 'Error al registrar',
    'auth.signup.login.account': '¿Ya tienes una cuenta con Qempo?',
    'auth.signup.login': 'Iniciar sesión',
  },
};
