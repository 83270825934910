import React, {useEffect} from 'react';
import {AppHelmet} from './index.helmet';
import {useSelector} from 'react-redux';
import Locale from './modules/locale/Locale';
import {Navigate, Route, Routes} from 'react-router';
import LiteHome from './modules/home/pages/LiteHome';
import Login from './modules/auth/pages/Login';
import SignUp from './modules/auth/pages/SignUp';
import LiteReserve from './modules/lite-reserve/pages/LiteReserve';
import LiteReservation from './modules/lite-reserve/pages/LiteReservation';
import {Navbar} from './modules/navbar/components/Navbar';
import {useActions} from '@qempo.io/web-common/redux';
import {actions as metaActions, MetaState} from './modules/meta/duck';
import {RootState} from './modules';
import {useSession} from './modules/auth/hooks';
import OrderIndex from './modules/order/pages';
import OrderCreated from './modules/order/pages/OrderCreated';
import OrderRegister from './modules/order/pages/OrderRegister';
import Order from './modules/order/pages/Order';
import OrdersIndex from './modules/orders/pages';
import OrdersPending from './modules/orders/pages/OrdersPending';
import OrdersCompleted from './modules/orders/pages/OrdersCompleted';
import {GlobalMessageProvider} from '@qempo.io/web-common/elements/GlobalMessageProvider';
/*import {Space} from '@qempo.io/web-common/elements/Space';
import {Alert} from '@qempo.io/web-common/elements/Alert';
import {Checkbox} from '@qempo.io/web-common/elements/Checkbox';
import {FloatingBottom} from '@qempo.io/web-common/elements/FloatingBottom';
import {Select} from '@qempo.io/web-common/elements/Select';
import {TabInfo} from '@qempo.io/web-common/elements/Tabs';
import {Input2} from '@qempo.io/web-common/elements/Input2';*/
// import {Input} from '@qempo.io/web-common/elements/Input';

function App() {
  const {categories, isFetchingCategories, fetchCategoriesError} = useSelector<
    RootState,
    MetaState
  >(({meta}) => meta);
  const {getCategories} = useActions({
    ...metaActions,
  });

  useSession();

  useEffect(() => {
    if (!categories && !isFetchingCategories && !fetchCategoriesError) {
      getCategories();
    }
  }, [categories, isFetchingCategories, fetchCategoriesError, getCategories]);

  // const [value, setValue] = useState('asasfsfa');

  // console.log('Input2', Input2);

  return (
    <GlobalMessageProvider
      style={{bottom: 24, left: 24, top: 'unset', right: 'unset'}}
    >
      <Locale>
        <AppHelmet />
        <Navbar />
        {/*<Input2
          id="sample"
          type="text"
          label="label"
          name="sample"
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />*/}
        <Routes>
          <Route path="/" element={<LiteHome />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/lite/reserve/:flightId/:categoryId"
            element={<LiteReserve />}
          />
          <Route
            path="/lite/reservation/:liteReserveId"
            element={<LiteReservation />}
          />
          <Route path="/order/:orderId" element={<OrderIndex />}>
            <Route path="success" element={<OrderCreated />} />
            <Route path="register" element={<OrderRegister />} />
            <Route path="" element={<Order />} />
          </Route>
          <Route path="/orders" element={<OrdersIndex />}>
            <Route path="pending" element={<OrdersPending />} />
            <Route path="confirmed" element={<OrdersCompleted />} />
            <Route path="*" element={<Navigate to="/orders/pending" />} />
            <Route path="" element={<Navigate to="/orders/pending" />} />
          </Route>
        </Routes>
      </Locale>
    </GlobalMessageProvider>
  );
}

export default App;
