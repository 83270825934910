import {Order, OrderItemState, OrderState} from '../../entities';
import {useEffect, useState} from 'react';
import {intls} from '@qempo.io/web-common';

export function useNormalizedState(
  order?: Order
): OrderState | OrderItemState | undefined {
  const [normalizedState, setNormalizedState] = useState<
    OrderState | OrderItemState | undefined
  >();

  useEffect(() => {
    if (order) {
      if (['CREATED', 'COMPLETED', 'CANCELED'].includes(order.state)) {
        setNormalizedState(order.state);
      }

      for (const st of [
        'ITEM_CREATED',
        'PURCHASED',
        'RECEIVED',
        'ARRIVED',
        'DELIVERED',
        'CANCELED',
      ] as OrderItemState[]) {
        if (order.items.find((i) => i.state === st)) {
          setNormalizedState(st === 'ITEM_CREATED' ? 'PAID' : st);
          break;
        }
      }
    }
  }, [order]);

  return normalizedState;
}

export function useStateTranslation(
  state: OrderState | OrderItemState | undefined
): string | undefined {
  const [stateLabel, setStateLabel] = useState<string>('');

  const [
    created,
    paid,
    purchased,
    received,
    arrived,
    delivered,
    completed,
    canceled,
  ] = intls([
    {
      id: 'order.state.created',
    },
    {
      id: 'order.state.paid',
    },
    {
      id: 'order.state.purchased',
    },
    {
      id: 'order.state.received',
    },
    {
      id: 'order.state.arrived',
    },
    {
      id: 'order.state.delivered',
    },
    {
      id: 'order.state.completed',
    },
    {
      id: 'order.state.canceled',
    },
  ]);

  useEffect(() => {
    switch (state) {
      case 'CREATED':
        setStateLabel(created);
        break;
      case 'PAID':
      case 'ITEM_CREATED':
        setStateLabel(paid);
        break;
      case 'PURCHASED':
        setStateLabel(purchased);
        break;
      case 'RECEIVED':
        setStateLabel(received);
        break;
      case 'ARRIVED':
        setStateLabel(arrived);
        break;
      case 'DELIVERED':
        setStateLabel(delivered);
        break;
      case 'COMPLETED':
        setStateLabel(completed);
        break;
      case 'CANCELED':
        setStateLabel(canceled);
        break;
    }
  }, [state]);

  return stateLabel;
}
