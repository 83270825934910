import React, {ReactNode, useMemo} from 'react';
import PropTypes from 'prop-types';
import {Icon, Space} from '@qempo.io/web-common';
import {Avatar} from '../Avatar';
import {useTraveler} from '../../modules/auth/hooks';

import style from './style.module.scss';
import {File} from '../../entities';

type Props = {
  subtitle1: string | ReactNode;
  subtitle2?: string | ReactNode;
  title?: string | ReactNode;
  image?: File;
  rating?: number;
  travelerId?: string;
  bottomRight?: ReactNode;
};

export function ProfileMini(props: Props) {
  const {title, subtitle1, subtitle2, image, rating, travelerId, bottomRight} =
    props;

  const traveler = useTraveler(travelerId);

  const titleContent = useMemo<string | ReactNode>(() => {
    if (title) return title;
    if (traveler) return `${traveler.firstName} ${traveler.lastName}.`;
  }, [title, traveler]);

  const imageContent = useMemo<string | undefined>(() => {
    if (image) return image.publicUrl;
    if (traveler && traveler.image) return traveler.image.publicUrl;
  }, [image, traveler]);

  return (
    <div className={style.profileMiniWrap}>
      <Avatar src={imageContent} className={style.image} />
      <div className={style.textWrap}>
        <p className={style.title}>{titleContent}</p>
        <Space multiplier={0.1} />
        <p className={style.subtitle}>{subtitle1}</p>
        {subtitle2 && (
          <>
            <Space multiplier={0.1} />
            <p className={style.subtitle}>{subtitle2}</p>
          </>
        )}
      </div>
      {typeof rating === 'number' && (
        <div className={style.topRightWrap}>
          <span>({rating.toFixed(1)})</span>
          <Icon icon="star" size={24} />
        </div>
      )}
      {bottomRight && (
        <div className={style.bottomRightWrap}>{bottomRight}</div>
      )}
    </div>
  );
}

ProfileMini.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  subtitle1: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  subtitle2: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  image: PropTypes.string,
  rating: PropTypes.number,
  bottomRight: PropTypes.node,
};
