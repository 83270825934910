import {getCreator} from '@qempo.io/web-common/http';
import environment from '../../environment';
import {Admin1, Admin2, Admin3} from '../../entities';

export const endpoints = Object.freeze({
  LIST_ADMIN1: `${environment.baseApiUrl}logistics/geography/admin1`,
  LIST_ADMIN2: `${environment.baseApiUrl}logistics/geography/admin2`,
  LIST_ADMIN3: `${environment.baseApiUrl}logistics/geography/admin3`,
});

export const listAdmin1Api = getCreator<Admin1[]>(endpoints.LIST_ADMIN1);
export const listAdmin2Api = getCreator<Admin2[]>(endpoints.LIST_ADMIN2);
export const listAdmin3Api = getCreator<Admin3[]>(endpoints.LIST_ADMIN3);
