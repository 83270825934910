import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {useProviderLabels} from '../../hooks';
import {
  PaymentMethods,
  PaymentProcessor,
  PaymentProvider,
} from '../../../../entities';

import style from './style.module.scss';
import {Space} from '@qempo.io/web-common';
import {Loading} from '../../../../elements/Loading';

type Props = {
  disabled?: boolean;
  selectedPaymentMethod?: {
    provider: PaymentProvider;
    processor: PaymentProcessor;
  };
  paymentMethods?: PaymentMethods;
  onSelectPaymentMethod: (
    provider: PaymentProvider,
    processor: PaymentProcessor
  ) => void;
};

export function CheckoutSelectPaymentMethod(props: Props): JSX.Element {
  const {
    disabled,
    selectedPaymentMethod,
    paymentMethods,
    onSelectPaymentMethod,
  } = props;
  const providerLabels = useProviderLabels();

  const methods = useMemo<[string, string][]>(() => {
    if (!paymentMethods) {
      return [];
    }
    return Object.entries(paymentMethods);
  }, [paymentMethods]);

  return (
    <>
      {(!methods || methods.length === 0) && (
        <>
          <Space multiplier={2} />
          <Loading />
          <Space multiplier={2} />
        </>
      )}
      {methods.map((m) => (
        <button
          key={m[0]}
          className={classNames(style.methodWrap, {
            [style.selected]:
              selectedPaymentMethod && selectedPaymentMethod.provider === m[0],
          })}
          disabled={disabled}
          onClick={() =>
            onSelectPaymentMethod(
              m[0] as PaymentProvider,
              m[1] as PaymentProcessor
            )
          }
        >
          <div className={style.contentWrap}>
            <span>{providerLabels[m[0] as PaymentProvider].label}</span>
            {providerLabels[m[0] as PaymentProvider].images.map((i, idx) => (
              <div key={`image-${idx}`} className={style.imageWrap}>
                <img
                  src={i}
                  alt={providerLabels[m[0] as PaymentProvider].label}
                />
              </div>
            ))}
          </div>
        </button>
      ))}
    </>
  );
}

CheckoutSelectPaymentMethod.propTypes = {
  disabled: PropTypes.bool,
  selectedPaymentMethod: PropTypes.object,
  paymentMethods: PropTypes.object,
  onSelectPaymentMethod: PropTypes.func.isRequired,
};
