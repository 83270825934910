import React, {useEffect} from 'react';
import queryString from 'query-string';
import {useSelector} from 'react-redux';
import classNames from 'classnames';
import {RootState} from '../../../index';
import {intl} from '@qempo.io/web-common/utils/i18n';
import {Input, Space} from '@qempo.io/web-common/elements';
import {useActions} from '@qempo.io/web-common/redux';
import {emailRegex} from '@qempo.io/web-common/utils/string';
import {Controller, useForm} from 'react-hook-form';
import {actions as authActions, AuthState} from '../../duck';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {logger} from '../../../../logger';
import {LoginHelmet} from './index.helmet';
import {decodeB64} from '@qempo.io/web-common/utils/crypto';
import {AuthPage} from '../../components/AuthPage';

import style from '../auth.module.scss';
import buttonStyle from '@qempo.io/web-common/assets/styles/button.module.scss';

export default function Login(): JSX.Element {
  const {loginError, isLogging, user} = useSelector<RootState, AuthState>(
    ({auth}) => auth
  );

  const {login} = useActions({...authActions});

  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = queryString.parse(location.search);

  useEffect(() => {
    if (user) {
      if (queryParams.redirect && typeof queryParams.redirect === 'string') {
        try {
          const redirect = decodeB64(queryParams.redirect);
          if (!redirect.startsWith('/'))
            throw new Error('Redirect url is not relative');
          navigate(redirect);
        } catch (e) {
          logger.error(
            `Could not decode base64 for redirect: ${queryParams.redirect}`,
            e
          );
        }
      } else {
        navigate('/');
      }
    }
  }, [user]);

  const {
    control,
    clearErrors,
    handleSubmit,
    formState: {errors},
  } = useForm({
    mode: 'onSubmit',
  });

  const handleOnSubmit = (data: {[key: string]: string}) => {
    const {email, password} = data;
    login({
      email,
      password,
    });
  };

  const loginErrorMessages = {
    error: intl({id: 'auth.login.error'}),
    unauthorized: intl({id: 'auth.login.error.unauthorized'}),
  };

  return (
    <>
      <LoginHelmet />
      <AuthPage>
        <form
          method="POST"
          onSubmit={handleSubmit(handleOnSubmit)}
          className={style.formWrap}
        >
          <h2 className={style.title}>{intl({id: 'auth.login.title'})}</h2>
          <Space multiplier={4} />
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{
              required: intl({id: 'auth.login.email.error.required'}),
              pattern: {
                value: emailRegex,
                message: intl({id: 'auth.login.email.error.format'}),
              },
            }}
            render={({field}) => (
              <Input
                id={field.name}
                label={intl({id: 'auth.login.email'})}
                name={field.name}
                onChange={field.onChange}
                onFocus={() => clearErrors(field.name)}
                value={field.value}
                error={errors[field.name]}
                disabled={isLogging}
              />
            )}
          />
          <Space multiplier={2} />
          <Controller
            name="password"
            control={control}
            defaultValue=""
            rules={{
              required: intl({
                id: 'auth.login.password.error.required',
              }),
              min: intl({id: 'auth.login.password.error.min'}),
            }}
            render={({field}) => (
              <Input
                id={field.name}
                label={intl({id: 'auth.login.password'})}
                name={field.name}
                onChange={field.onChange}
                onFocus={() => clearErrors(field.name)}
                value={field.value}
                error={errors[field.name]}
                type="password"
                disabled={isLogging}
              />
            )}
          />
          {loginError && (
            <>
              <Space multiplier={3} />
              <p className={style.error}>
                {loginError.error === 'Unauthorized' &&
                  loginErrorMessages.unauthorized}
                {loginError.error !== 'Unauthorized' &&
                  loginErrorMessages.error}
              </p>
            </>
          )}
          <Space multiplier={4} />
          <button
            type="submit"
            disabled={isLogging}
            className={classNames(buttonStyle.btn, buttonStyle.blue)}
          >
            {intl({id: 'auth.login.btn'})}
          </button>
          <Space multiplier={5} />
          <hr />
          <Space multiplier={2} />
          <Link
            to="/signup"
            className={classNames(style.link, {[style.disabled]: isLogging})}
          >
            {intl({id: 'auth.login.register.signup'})}
          </Link>
        </form>
      </AuthPage>
    </>
  );
}
