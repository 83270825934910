import React, {useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {OrderItem} from '../../../../../../entities';
import {FileRejection, useDropzone} from 'react-dropzone';
import classNames from 'classnames';
import {intls, Space} from '@qempo.io/web-common';
import {Icon} from '@qempo.io/web-common/elements';

import style from './style.module.scss';
import pdfPlaceholder from '../../../../../../assets/images/order/pdf-file-placeholder.png';
import {buildThumbnailUrl} from '../../../../../../utils/image';

type Props = {
  orderItem: OrderItem;
  onChangeFile: (file: File) => void;
  onErrorFile: (errorType: string) => void;
  onRemoveFile: () => void;
  error?: {
    message: string;
  };
  disabled?: boolean;
};

type FileInfo = {
  name: string;
  type: string;
  src?: string;
};

export default function UploadInvoice(props: Props) {
  const {orderItem, onChangeFile, onRemoveFile, onErrorFile, error, disabled} =
    props;

  const [file, setFile] = useState<FileInfo | undefined>(() => {
    if (orderItem.itemTracking?.invoiceFile) {
      const invoiceFile = orderItem.itemTracking.invoiceFile;
      const hasImage = invoiceFile.mimeType.startsWith('image');

      return {
        name: invoiceFile.fileName,
        type: invoiceFile.mimeType,
        src: hasImage ? buildThumbnailUrl(invoiceFile, 80, 80) : undefined,
      };
    }
  });

  const [uploadLabel] = intls([{id: 'order.item.tracking.upload'}]);

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      const file = acceptedFiles[0];
      const rejectedFile = fileRejections[0];

      if (rejectedFile) {
        const error = rejectedFile.errors[0];

        if (error.code === 'file-too-large') {
          onErrorFile('max');
        }

        return;
      }

      if (file) {
        const reader = new FileReader();
        const fi: FileInfo = {
          name: file.name,
          type: file.type,
        };

        if (file.type.startsWith('image')) {
          reader.onload = (event) => {
            if (event.target) {
              fi.src = event.target.result as string;
              setFile(fi);
            }
          };
        } else {
          setFile(fi);
        }

        reader.readAsDataURL(file);

        onChangeFile(file);
      }
    },
    [setFile, onChangeFile, onErrorFile]
  );

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    multiple: false,
    onDrop,
    maxSize: 6291456, // 6MB
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'image/webp': ['.webp'],
      'application/pdf': ['.pdf'],
    },
  });

  const className = useMemo(() => {
    const classes = {
      [style.dragActive]: isDragActive,
      [style.error]: error,
    };

    return classNames(style.dropWrap, {...classes});
  }, [isDragActive, error]);

  const formatMimetype = (mimeType: string) => {
    const parts = mimeType.split('/');
    if (parts.length === 2) {
      return `${parts[0]} ${parts[1].toUpperCase()}`;
    }

    return mimeType;
  };

  return (
    <div className={style.uploadInvoiceWrap}>
      {file && (
        <div className={style.fileWrap}>
          <div className={style.imageWrap}>
            <img src={file.src ? file.src : pdfPlaceholder} alt={file.name} />
          </div>
          <div className={style.infoWrap}>
            <p className={style.title}>{file.name}</p>
            <Space multiplier={0.5} />
            <p className={style.subtitle}>{formatMimetype(file.type)}</p>
          </div>
          <Space multiplier={2} vertical />
          <button
            onClick={() => {
              setFile(undefined);
              onRemoveFile();
            }}
            disabled={disabled}
          >
            <Icon icon="xmark" size={24} />
          </button>
        </div>
      )}
      {!file && (
        <div {...getRootProps({className})}>
          <input {...getInputProps()} />
          <p>
            <Icon icon="cloud-arrow-up" size={24} /> {uploadLabel}
          </p>
        </div>
      )}
    </div>
  );
}

UploadInvoice.propTypes = {
  orderItem: PropTypes.object.isRequired,
  onChangeFile: PropTypes.func.isRequired,
  onErrorFile: PropTypes.func.isRequired,
  onRemoveFile: PropTypes.func,
  error: PropTypes.object,
  disabled: PropTypes.bool,
};
