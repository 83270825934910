import React from 'react';
import {PayPalButtons, usePayPalScriptReducer} from '@paypal/react-paypal-js';
import {
  CreateOrderActions,
  OnApproveData,
} from '@paypal/paypal-js/types/components/buttons';
import {Loading} from '../../../../elements/Loading';
import PropTypes from 'prop-types';

type Props = {
  total?: string;
  onComplete: (processorData: Record<string, string>) => void;
  onError: () => void;
};

export function Paypal({total, onComplete, onError}: Props) {
  const [{isPending}] = usePayPalScriptReducer();

  const createOrder = (
    data: Record<string, unknown>,
    actions: CreateOrderActions
  ) => {
    if (!total) return Promise.reject(new Error('Invalid total'));

    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: total,
            currency_code: 'USD',
          },
        },
      ],
    });
  };

  const onApprove = (data: OnApproveData): Promise<void> => {
    onComplete({orderId: data.orderID});
    return Promise.resolve();
  };

  /*const onError = (error: Record<string, unknown>) => {};

  const onCancel = () => {};*/

  return isPending || !total ? (
    <Loading />
  ) : (
    <PayPalButtons
      style={{layout: 'horizontal', color: 'blue'}}
      createOrder={createOrder}
      onApprove={onApprove}
      onError={() => onError()}
    />
  );
}

Paypal.propTypes = {
  total: PropTypes.string,
  onComplete: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};
