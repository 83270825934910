import React, {useEffect, useMemo, useState} from 'react';
import {Icon, intl, Space, useActions} from '@qempo.io/web-common';
import {useSelector} from 'react-redux';
import {RootState} from '../../../index';
import {Category} from '../../../../entities';
import {CategoryModal} from '../../components/CategoryModal';
import {useCategoryTranslation} from '../../../meta/utils';
import {Card} from '../../../../elements/Card';
import {TravelerProfileMini} from '../../components/TravelerProfileMini';
import {actions as homeActions, HomeState} from '../../duck';
import {Loading} from '../../../../elements/Loading';
// import {useNavigate} from 'react-router-dom';
import style from './style.module.scss';

import world from '../../../../assets/images/home/world.png';

export default function LiteHome(): JSX.Element {
  const title = intl({id: 'home.lite.title'});
  const subtitle = intl({id: 'home.lite.subtitle'});
  const buttonLabel = intl({id: 'home.lite.button.label'});

  const travelersTitle = intl({id: 'home.lite.travelers.title'});

  const categories = useSelector<RootState, Category[] | undefined>(
    (s) => s.meta.categories
  );

  const {isFetchingFlights, flights} = useSelector<RootState, HomeState>(
    (s) => s.home
  );

  const {fetchFlights} = useActions({...homeActions});

  const [modelOpen, setModalOpen] = useState(false);
  const [categoryId, setCategoryId] = useState<string>();

  // const [preferredFlight, setPreferredFlight] = useState<Flight>();

  // const navigate = useNavigate();

  const selectedCategory = useMemo<Category | undefined>(() => {
    if (categories) {
      return categories.find((c) => c.id === categoryId);
    }
  }, [categories, categoryId]);

  const selectedCategoryName = useCategoryTranslation(selectedCategory);

  useEffect(() => {
    fetchFlights({categoryId});
  }, [categoryId]);

  return (
    <>
      <div className={style.homeWrap}>
        <div className={style.topWrap}>
          <div className={style.content}>
            <Space multiplier={6} />
            <h1>{title}</h1>
            <Space multiplier={1} />
            <p className={style.subtitle}>{subtitle}</p>
            <Space multiplier={2} />
            <div className={style.buttonWrap}>
              <button
                className={style.choose}
                onClick={() => setModalOpen(true)}
              >
                {selectedCategory ? selectedCategoryName : buttonLabel}
              </button>
              <Space multiplier={1} vertical />
              {selectedCategory && (
                <button
                  className={style.edit}
                  onClick={() => setModalOpen(true)}
                >
                  <Icon icon="pencil" />
                </button>
              )}
            </div>
            <Space multiplier={3} />
          </div>
          <img className={style.background} src={world} alt={world} />
        </div>
        <Card mainWrap className={style.cardWrap}>
          <h3>{travelersTitle}</h3>
          <Space multiplier={2} />
          {isFetchingFlights && (
            <>
              <Space multiplier={2} />
              <Loading />
              <Space multiplier={5} />
            </>
          )}
          <div className={style.flightsWrap}>
            {!isFetchingFlights &&
              flights &&
              flights.map((f) => (
                <TravelerProfileMini
                  key={f.id}
                  flight={f}
                  categoryId={categoryId}
                  onClick={(e) => {
                    if (!categoryId) {
                      // setPreferredFlight(f);
                      setModalOpen(true);
                      e.preventDefault();
                    }
                  }}
                />
              ))}
          </div>
          <Space multiplier={2} />
        </Card>
      </div>
      <CategoryModal
        open={modelOpen}
        onClose={() => setModalOpen(false)}
        handleSelectCategory={(categoryId) => {
          /*if (preferredFlight && preferredFlight.litePricingCommission) {
            if (
              preferredFlight.litePricingCommission.pricingCommissionFormulas.find(
                (f) => f.category.id === categoryId
              )
            ) {
              setPreferredFlight(undefined);
              navigate(`/lite/reserve/${preferredFlight.id}/${categoryId}`);
            } else {
              setCategoryId(categoryId);
            }
          } else {
            setCategoryId(categoryId);
          }*/
          setCategoryId(categoryId);
        }}
        categories={categories}
        categoryId={categoryId}
      />
    </>
  );
}
