import React, {MutableRefObject} from 'react';
import PropTypes from 'prop-types';
import {sendMessage, Space, useMessage} from '@qempo.io/web-common';
import {
  PaymentProcessorReceiveEvent,
  PaymentProcessorSendEvent,
} from '../../../../entities';
import env from '../../../../environment';

type Props = {
  selectedIframe: MutableRefObject<HTMLIFrameElement | null>;
  onReady: () => void;
  name?: string;
  email?: string;
};

export function Spreedly(props: Props) {
  const {selectedIframe, onReady, name, email} = props;

  useMessage(
    PaymentProcessorReceiveEvent.READY_TO_SETUP,
    env.allowedTargetOrigins,
    () => {
      if (selectedIframe.current && selectedIframe.current.contentWindow) {
        sendMessage(
          {
            event: PaymentProcessorSendEvent.SETUP,
            name,
            email,
          },
          ['*'],
          selectedIframe.current.contentWindow
        );
      }
      onReady();
    }
  );

  return (
    <>
      <Space multiplier={2} />
      <iframe
        ref={selectedIframe}
        src={`${env.paymentGatewayUrl}?processor=SPREEDLY`}
        frameBorder="0"
        style={{width: '100%', height: '260px'}}
      />
    </>
  );
}

Spreedly.propTypes = {
  selectedIframe: PropTypes.object.isRequired,
  onReady: PropTypes.func.isRequired,
  name: PropTypes.string,
  email: PropTypes.string,
};
