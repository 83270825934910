import {formatPriceNumber, setPrecision} from '@qempo.io/web-common';

export const decimalRegex = /\d+?.\d{1,5}/g;

export function formatPricePrecision(number: string | number, currency = '$') {
  return `${currency}${setPrecision(formatPriceNumber(number))}`;
}

export function formatFormulaPrices(formula: string, currency?: string) {
  let f = formula;
  const matches = formula.match(decimalRegex);
  if (!matches) return formula;

  matches.forEach((m) => {
    f = f.replace(m, formatPricePrecision(m, currency));
  });

  return f;
}
