import React from 'react';
import PropTypes from 'prop-types';

import loading from '../../assets/images/loading-circle.gif';
import style from './style.module.scss';

type Props = {
  size?: number;
};

export function Loading(props: Props) {
  const {size = 45} = props;
  return (
    <div className={style.loadingWrap}>
      <img style={{width: size, height: size}} src={loading} alt="loading" />
    </div>
  );
}

Loading.propTypes = {
  size: PropTypes.number,
};
