import React, {ReactNode} from 'react';
import PropTypes from 'prop-types';
import {Space} from '@qempo.io/web-common/elements';
import {intls} from '@qempo.io/web-common';

import style from './style.module.scss';
import banner from '../../../../assets/images/auth/banner.png';

type Props = {
  children: ReactNode;
};

export function AuthPage(props: Props) {
  const {children} = props;

  const [titleIntl, subtitleIntl] = intls([
    {id: 'auth.title'},
    {id: 'auth.subtitle'},
  ]);

  return (
    <div className={style.authWrap}>
      <div className={style.leftWrap}>
        <div className={style.contentWrap}>
          <Space multiplier={8} />
          {children}
          <Space multiplier={8} />
        </div>
      </div>
      <div className={style.rightWrap}>
        <div className={style.contentWrap}>
          <h1>{titleIntl}</h1>
          <p>{subtitleIntl}</p>
          <Space multiplier={4} />
          <img className={style.banner} src={banner} alt="world" />
        </div>
      </div>
    </div>
  );
}

AuthPage.propTypes = {
  children: PropTypes.node,
};
