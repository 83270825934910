import React from 'react';
import PropTypes from 'prop-types';
import {ShippingAddress as ShippingAddressEntity} from '../../../../entities';
import {useAddressLabel} from '../../../address/hooks';

import style from './style.module.scss';

type Props = {
  shippingAddress: ShippingAddressEntity;
};

export function ShippingAddress(props: Props) {
  const {shippingAddress} = props;

  const {
    firstName,
    // lastName,
    address1,
    address2,
    admin1,
    admin2,
    admin3,
    zipCode,
    phone,
  } = useAddressLabel('PE');

  return (
    <div className={style.shippingAddressWrap}>
      <p className={style.name}>
        {firstName}: {shippingAddress.firstName} {shippingAddress.lastName}
      </p>
      {/*<p>
        {lastName}: {shippingAddress.lastName}
      </p>*/}
      <p className={style.address1}>
        {address1}: {shippingAddress.address1}
      </p>
      {shippingAddress.address2 && (
        <p className={style.address2}>
          {address2}: {shippingAddress.address2}
        </p>
      )}
      <p>
        {admin1}: {shippingAddress.admin1.name}
      </p>
      {admin2 && (
        <p>
          {admin2}: {shippingAddress.admin2.name}
        </p>
      )}
      {admin3 && (
        <p>
          {admin3}: {shippingAddress.admin3.name}
        </p>
      )}
      <p>
        {zipCode}: {shippingAddress.zipCode}
      </p>
      <p>
        {phone}: {shippingAddress.phone}
      </p>
    </div>
  );
}

ShippingAddress.propTypes = {
  shippingAddress: PropTypes.object.isRequired,
};
