import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Space} from '@qempo.io/web-common';
import {Category} from '../../../../entities';
import {useCategoryTranslation} from '../../../meta/utils';

import style from './style.module.scss';

type Props = {
  selected: boolean;
  category: Category;
  onClick: (category: Category) => void;
};

export function CategorySelect(props: Props) {
  const {selected, category, onClick} = props;

  const categoryName = useCategoryTranslation(category);

  return (
    <div
      className={classNames(style.categorySelectWrap, {
        [style.active]: selected,
      })}
      onClick={() => onClick(category)}
    >
      <div className={style.bullet} />
      <Space multiplier={2} vertical />
      <span className={style.label}>{categoryName}</span>
    </div>
  );
}

CategorySelect.propTypes = {
  selected: PropTypes.bool.isRequired,
  category: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};
