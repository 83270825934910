export type PaymentProcessor =
  | 'CULQI'
  | 'NIUBIZ_WEB'
  | 'PAYPAL'
  | 'SPREEDLY'
  | 'KASHIO';

export type PaymentProvider =
  | 'CARD'
  | 'PAYPAL'
  | 'SPREEDLY'
  | 'TRANSFER'
  | 'CASH';

export type PaymentMethods = {
  [key in PaymentProvider | string]: PaymentProcessor;
};

export enum PaymentProcessorReceiveEvent {
  CLOSE = 'CLOSE',
  READY_TO_SETUP = 'READY_TO_SETUP',
  CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS',
  CHECKOUT_ERROR = 'CHECKOUT_ERROR',
}

export enum PaymentProcessorSendEvent {
  SETUP = 'SETUP',
  EXECUTE_CHECKOUT = 'EXECUTE_CHECKOUT',
}
