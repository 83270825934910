import {getCreator, postCreator} from '@qempo.io/web-common/http';
import environment from '../../environment';
import {Invoice, Order} from '../../entities';

export const endpoints = Object.freeze({
  GET_ORDER: `${environment.baseApiUrl}logistics/order/:orderId`,
  GET_INVOICE: `${environment.baseApiUrl}logistics/checkout/invoice/:invoiceId`,
  REGISTER_ITEMS: `${environment.baseApiUrl}logistics/order/:orderId/register`,
});

export type RegisterItemApiRequest = Record<string, string> & {
  orderItemId: string;
  orderNumber: string;
  trackingNumber: string;
  invoiceFile: File;
};

export const getOrderApi = getCreator<Order>(endpoints.GET_ORDER);
export const getInvoiceApi = getCreator<Invoice>(endpoints.GET_INVOICE);
export const registerItemsApi = postCreator<Order>(endpoints.REGISTER_ITEMS);
