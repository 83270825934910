import {File} from '../entities';

export function buildThumbnailUrl(
  file: File,
  width: number,
  height: number
): string {
  console.log(width, height);
  return `${file.publicRootUrl}${file.path}${file.fileName}`;
  /*if (!file.mimeType.startsWith('image'))
    return `${file.publicRootUrl}${file.path}${file.fileName}`;
  return `${file.publicRootUrl}${file.path}${width}x${height}/${file.fileName}`;*/
}
