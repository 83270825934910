import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {OrderItem as OrderItemEntity} from '../../../../../../entities';
import {useParams} from 'react-router';
import {intls, Space} from '@qempo.io/web-common';
import {useStateTranslation} from '../../../../hooks';
import {Link} from 'react-router-dom';

import style from './style.module.scss';
import {SummaryNamePrice} from '../../../../components/Summary';

type Props = {
  orderItem: OrderItemEntity;
};

export function OrderItem({orderItem}: Props) {
  const {orderId} = useParams();

  const [itemQuantity, itemTracking, addTracking] = intls([
    {id: 'order.item.quantity'},
    {id: 'order.item.tracking'},
    {id: 'order.item.tracking.add'},
  ]);

  const hasCompleteTracking = useMemo<boolean>(
    () =>
      !!(
        orderItem.itemTracking &&
        orderItem.itemTracking.orderNumber &&
        orderItem.itemTracking.trackingNumber &&
        orderItem.itemTracking.invoiceFile
      ),
    [orderItem]
  );

  const stateLabel = useStateTranslation(orderItem.state);

  return (
    <div className={style.productWrap}>
      <SummaryNamePrice name={orderItem.name} price={orderItem.price} />
      <Space multiplier={1} />
      <div>
        <p>
          {itemQuantity}: {orderItem.quantity}
        </p>
      </div>
      <Space multiplier={1} />
      <div className={style.tracking}>
        <p>
          {itemTracking}: {orderItem.itemTracking?.trackingNumber}
        </p>
        <Space multiplier={1} vertical />
        {!hasCompleteTracking && (
          <Link
            to={`/order/${orderId}/register`}
            className={style.trackingButton}
          >
            {addTracking}
          </Link>
        )}
      </div>
      <Space multiplier={2} />
      <div className={style.stateWrap}>
        <span>{stateLabel}</span>
      </div>
    </div>
  );
}

OrderItem.propTypes = {
  orderItem: PropTypes.object.isRequired,
};
