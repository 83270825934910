import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {combineEpics, ofType, Epic} from 'redux-observable';
import {catchError, map, switchMap} from 'rxjs/operators';
import {loginApi, getSessionApi, loginFacebookApi, signUpApi} from './api';
import {GenericAction, GenericActionPayload} from '@qempo.io/web-common/redux';
import {User} from '../../entities';
import {of} from 'rxjs';
import {ApiError} from '@qempo.io/web-common/http';
import {RootState} from '../index';

export type AuthState = {
  isLogging?: boolean;
  isSigningUp?: boolean;
  isGettingSession?: boolean;
  initialSession?: boolean;
  user?: User;
  loginError?: ApiError;
  signUpError?: ApiError;
};

export type AuthAction = GenericActionPayload & {
  email?: string;
  password?: string;
  user?: User;
  error?: ApiError;
  facebookToken?: string;
};

type AuthEpic = Epic<
  GenericAction<AuthAction>,
  GenericAction<AuthAction>,
  RootState
>;

export const initialState: AuthState = {};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    login: (s, action: PayloadAction<AuthAction>) => {
      s.isLogging = true;
      s.loginError = undefined;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loginFacebook: (s, action: PayloadAction<AuthAction>) => {
      s.isLogging = true;
      s.loginError = undefined;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    signUp: (s, action: PayloadAction<AuthAction>) => {
      s.isSigningUp = true;
      s.signUpError = undefined;
    },
    loginSuccess: (s, {payload: {user}}: PayloadAction<AuthAction>) => {
      s.user = user;
      s.loginError = undefined;
      s.isLogging = undefined;
      s.isGettingSession = undefined;
    },
    signUpSuccess: (s, {payload: {user}}: PayloadAction<AuthAction>) => {
      s.user = user;
      s.signUpError = undefined;
      s.isSigningUp = undefined;
      s.isGettingSession = undefined;
    },
    loginError: (s, {payload: {error}}: PayloadAction<AuthAction>) => {
      s.isLogging = undefined;
      s.loginError = error;
    },
    signUpError: (s, {payload: {error}}: PayloadAction<AuthAction>) => {
      s.isSigningUp = undefined;
      s.signUpError = error;
    },
    getSession: (s) => {
      s.isGettingSession = true;
    },
    getSessionSuccess: (s, {payload: {user}}: PayloadAction<AuthAction>) => {
      s.user = user;
      s.loginError = undefined;
      s.isLogging = undefined;
      s.isGettingSession = undefined;
      s.initialSession = true;
    },
    getSessionError: (s, {}: PayloadAction<AuthAction>) => {
      s.isGettingSession = undefined;
      s.initialSession = true;
    },
    resetErrors: (s) => {
      s.loginError = undefined;
    },
  },
});

export default slice.reducer;

const {
  login,
  loginFacebook,
  signUp,
  loginSuccess,
  signUpSuccess,
  loginError,
  signUpError,
  getSession,
  getSessionSuccess,
  getSessionError,
  resetErrors,
} = slice.actions;

export const actions = {
  login,
  loginFacebook,
  getSession,
  resetErrors,
  signUp,
};

const loginEpic: AuthEpic = (action$) =>
  action$.pipe(
    ofType(login),
    switchMap(({payload}) =>
      loginApi(payload).pipe(
        map((user: User) => loginSuccess({user})),
        catchError((error: ApiError) => of(loginError({error})))
      )
    )
  );

const signUpEpic: AuthEpic = (action$) =>
  action$.pipe(
    ofType(signUp),
    switchMap(({payload}) =>
      signUpApi(payload).pipe(
        map((user: User) => signUpSuccess({user})),
        catchError((error: ApiError) => of(signUpError({error})))
      )
    )
  );

const loginFacebookEpic: AuthEpic = (action$) =>
  action$.pipe(
    ofType(loginFacebook),
    switchMap(({payload}) =>
      loginFacebookApi(payload).pipe(
        map((user: User) => loginSuccess({user})),
        catchError((error: ApiError) => of(loginError({error})))
      )
    )
  );

const getSessionEpic: AuthEpic = (action$) =>
  action$.pipe(
    ofType(getSession),
    switchMap(() =>
      getSessionApi().pipe(
        map((user: User) =>
          user ? getSessionSuccess({user}) : getSessionError({})
        ),
        catchError(() => of(getSessionError({})))
      )
    )
  );

export const epics = combineEpics(
  loginEpic,
  loginFacebookEpic,
  getSessionEpic,
  signUpEpic
);
