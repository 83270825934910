import React, {useEffect} from 'react';
import {useParams} from 'react-router';
import {useSelector} from 'react-redux';
import classNames from 'classnames';
import {
  FloatingBottom,
  formatDate,
  intls,
  parseFormattedStringNumber,
  Space,
  useActions,
} from '@qempo.io/web-common';
import {useForm} from 'react-hook-form';
import {SubNavbar} from '../../../navbar/components/SubNavbar';
import {Card} from '../../../../elements/Card';
import {RootState} from '../../../index';
import {useCategory, useCategoryTranslation} from '../../../meta/utils';
import {actions, LiteReserveState} from '../../duck';
import {ToggleMenu} from '../../../../elements/ToggleMenu';
import {ProfileMini} from '../../../../elements/ProfileMini';
import {ProductForm} from '../../components/ProductForm';
import {ShippingForm} from '../../components/ShippingForm';
import {useNavigate} from 'react-router-dom';

import style from './style.module.scss';
import buttonStyle from '@qempo.io/web-common/assets/styles/button.module.scss';

export default function LiteReserve() {
  const navigate = useNavigate();
  const {flightId, categoryId} = useParams();

  const {createLiteReserve, calculatePricing, fetchFlight, resetState} =
    useActions(actions);

  const {
    isReserving,
    liteReserve,
    pricing,
    isFetchingPricing,
    flight,
    isFetchingFlight,
    fetchFlightError,
    traveler,
  } = useSelector<RootState, LiteReserveState>((s) => s.liteReserve);

  const lang = useSelector<RootState, string>((s) => s.locale.lang);

  const category = useCategory(categoryId);
  const categoryName = useCategoryTranslation(category);

  const {
    control,
    clearErrors,
    setValue,
    trigger,
    watch,
    handleSubmit,
    formState: {errors},
  } = useForm({
    mode: 'onBlur',
  });

  const [
    reserveIntl,
    travelerIntl,
    productIntl,
    shippingIntl,
    reserveButtonIntl,
  ] = intls([
    {
      id: 'lite.reserve.reserve',
      suffix: ` ${categoryName}`,
    },
    {id: 'lite.reserve.menu.traveler'},
    {id: 'lite.reserve.menu.product'},
    {id: 'lite.reserve.menu.shipping'},
    {id: 'lite.reserve.reserve.button'},
  ]);

  useEffect(() => {
    if (!isFetchingFlight && !flight && !fetchFlightError) {
      fetchFlight({flightId});
    }
  }, [fetchFlight, flight, isFetchingFlight, fetchFlightError]);

  useEffect(() => {
    return () => {
      resetState();
    };
  }, [resetState]);

  useEffect(() => {
    if (flight && flight.litePricingCommission) {
      calculatePricing({
        items: [
          {
            categoryId,
            quantity: 1,
          },
        ],
        pricingId: flight.litePricingCommission.id,
      });
    }
  }, [flight]);

  useEffect(() => {
    if (categoryName) setValue('product.category', categoryName);
  }, [categoryName]);

  useEffect(() => {
    if (liteReserve) {
      navigate(`/lite/reservation/${liteReserve.id}`);
    }
  }, [liteReserve]);

  const onSubmit = (data: any) => {
    createLiteReserve({
      createLiteReserveBody: {
        flightId,
        purchaseCountry: 'US',
        items: [
          {
            name: data['product'].name,
            url: data['product'].url,
            price: parseFormattedStringNumber(data['product'].price).toFixed(2),
            quantity: 1,
            categoryId,
          },
        ],
        shippingAddress: {
          firstName: data.shippingAddress.firstName,
          lastName: data.shippingAddress.lastName,
          idNumber: data.shippingAddress.idNumber,
          phone: data.shippingAddress.phone.replace(/ /g, ''),
          phoneCountry: 'PE',
          country: 'PE',
          admin1Id: data.shippingAddress.admin1.value,
          admin2Id: data.shippingAddress.admin2.value,
          admin3Id: data.shippingAddress.admin3.value,
          address1: data.shippingAddress.address1,
          address2: data.shippingAddress.address2,
          zipCode: data.shippingAddress.zipCode,
        },
      },
    });
  };

  return (
    <>
      <SubNavbar />
      <div className={style.liteReserveWrap}>
        <Space multiplier={0.5} />

        <Card mainWrap className={style.cardWrap}>
          <h3>{reserveIntl}</h3>
          <Space multiplier={5} />
          <div className={style.contentWrap}>
            <div>
              <ToggleMenu title={travelerIntl} initialOpen>
                <Space multiplier={2} />
                <ProfileMini
                  title={
                    traveler
                      ? `${traveler.firstName} ${traveler.lastName}`
                      : undefined
                  }
                  image={traveler ? traveler.image : undefined}
                  subtitle1={
                    flight
                      ? formatDate(new Date(flight.date), 'dd MMMM, yyyy', lang)
                      : ''
                  }
                  subtitle2={
                    flight
                      ? `${flight.departureAdmin1.name} - ${flight.arrivalAdmin1.name}`
                      : ''
                  }
                />
                <Space multiplier={2} />
              </ToggleMenu>
              <Space multiplier={2} />
              <ToggleMenu noSeparator title={shippingIntl} initialOpen>
                <ShippingForm
                  control={control}
                  trigger={trigger}
                  watch={watch}
                  clearErrors={clearErrors}
                  errors={errors}
                  setValue={setValue}
                  disabled={isReserving}
                  expandInputs
                />
                <Space multiplier={2} />
              </ToggleMenu>
              <Space multiplier={2} />
            </div>
            <div>
              <ToggleMenu noSeparator title={productIntl} initialOpen>
                <ProductForm
                  control={control}
                  trigger={trigger}
                  clearErrors={clearErrors}
                  errors={errors.product ? errors.product : {}}
                  setValue={setValue}
                  disabled={isReserving}
                  pricing={pricing}
                  isFetchingPricing={isFetchingPricing}
                />
                <Space multiplier={2} />
              </ToggleMenu>
              <Space multiplier={2} />
              <button
                className={classNames(
                  buttonStyle.btn,
                  buttonStyle.blue,
                  style.reserveButton
                )}
                onClick={handleSubmit(onSubmit)}
                disabled={isReserving}
              >
                {reserveButtonIntl}
              </button>
            </div>
          </div>
        </Card>
      </div>
      <FloatingBottom className={style.floatingBottom}>
        <button
          className={classNames(buttonStyle.btn, buttonStyle.blue)}
          onClick={handleSubmit(onSubmit)}
          disabled={isReserving}
        >
          {reserveButtonIntl}
        </button>
      </FloatingBottom>
    </>
  );
}
