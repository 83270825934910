import React, {ReactNode, useMemo, useRef, useState} from 'react';
import {
  PaymentProcessor,
  PaymentProcessorSendEvent,
  PaymentProvider,
} from '../../../entities';
import {PayPalScriptProvider} from '@paypal/react-paypal-js';
import env from '../../../environment';
import {Paypal} from './Paypal';
import classNames from 'classnames';
import {intl, sendMessage} from '@qempo.io/web-common';
import {formatPricePrecision} from '../../../utils/string';
import {Culqi} from './Culqi';
import {Spreedly} from './Spreedly';
import {CheckoutConfig} from '../hooks';

import buttonStyle from '@qempo.io/web-common/assets/styles/button.module.scss';

export type GetProcessorContentConfig = CheckoutConfig & {
  setPaymentMethod: (paymentMethod?: {
    provider: PaymentProvider;
    processor: PaymentProcessor;
  }) => void;
};

export function useProcessorContent(
  paymentMethod:
    | {provider: PaymentProvider; processor: PaymentProcessor}
    | undefined,
  config: GetProcessorContentConfig
): {
  content: ReactNode;
  button: ReactNode;
} {
  const [ready, setReady] = useState(false);
  const [open, setOpen] = useState(false);
  const selectedIframe = useRef<HTMLIFrameElement | null>(null);

  const checkoutPayLabel = intl({id: 'checkout.pay'});

  const content = useMemo<ReactNode>(() => {
    if (!paymentMethod) return null;

    const {setPaymentMethod, name, email, onComplete, onError} = config;

    if (paymentMethod.processor === 'CULQI') {
      return (
        <Culqi
          selectedIframe={selectedIframe}
          open={open}
          onReady={() => setReady(true)}
          onComplete={() => {
            onComplete(paymentMethod.provider, 'CULQI');
          }}
          onError={() => onError()}
          onClose={() => {
            setReady(false);
            setOpen(false);
            setPaymentMethod(undefined);
            selectedIframe.current = null;
          }}
        />
      );
    } else if (paymentMethod.processor === 'SPREEDLY') {
      return (
        <Spreedly
          selectedIframe={selectedIframe}
          name={name}
          email={email}
          onReady={() => setReady(true)}
        />
      );
    }
  }, [paymentMethod, config]);

  const button = useMemo<ReactNode>(() => {
    const {total, currency, onComplete, onError} = config;

    if (paymentMethod) {
      if (paymentMethod.processor === 'PAYPAL') {
        return (
          <PayPalScriptProvider
            options={{
              'client-id': env.paypal,
              currency,
            }}
          >
            <Paypal
              total={total}
              onComplete={(processorData) => {
                onComplete(paymentMethod.provider, 'PAYPAL', processorData);
              }}
              onError={() => onError()}
            />
          </PayPalScriptProvider>
        );
      }
    }

    const onClick = () => {
      if (!paymentMethod) return;

      if (paymentMethod.processor === 'CULQI') {
        if (selectedIframe.current && selectedIframe.current.contentWindow) {
          const amount = config.total
            ? parseInt(config.total.replace('.', ''), 10)
            : undefined;

          setOpen(true);

          sendMessage(
            {
              event: PaymentProcessorSendEvent.SETUP,
              title: 'Qempo',
              currency: config.currency,
              description: 'items',
              amount,
              lang: config.lang,
            },
            ['*'],
            selectedIframe.current.contentWindow
          );
        }
      }

      if (paymentMethod.processor === 'SPREEDLY') {
        if (selectedIframe.current && selectedIframe.current.contentWindow) {
          sendMessage(
            {
              event: PaymentProcessorSendEvent.EXECUTE_CHECKOUT,
            },
            ['*'],
            selectedIframe.current.contentWindow
          );
        }
      }
    };

    return (
      <button
        className={classNames(buttonStyle.btn, buttonStyle.blue)}
        onClick={onClick}
        disabled={!ready || !paymentMethod}
      >
        {checkoutPayLabel}
        {total && ` ${formatPricePrecision(total)}`}
      </button>
    );
  }, [paymentMethod, config, ready, checkoutPayLabel]);

  return {content, button};
}
