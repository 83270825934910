import React from 'react';
import classNames from 'classnames';
import {Outlet, useLocation} from 'react-router';
import {intls, Space} from '@qempo.io/web-common';
import {SubNavbar} from '../../navbar/components/SubNavbar';
import {Card} from '../../../elements/Card';
import {Link} from 'react-router-dom';

import style from './style.module.scss';

export default function OrdersIndex() {
  const [title, pendingTitle, completedTitle] = intls([
    {id: 'orders.title'},
    {id: 'orders.pending.title'},
    {id: 'orders.completed.title'},
  ]);

  const location = useLocation();

  return (
    <>
      <SubNavbar />
      <div className={style.ordersWrap}>
        <Space multiplier={0.5} />
        <Card className={style.cardWrap}>
          <Space multiplier={1} />
          <h1>{title}</h1>
          <Space multiplier={2} />
          <hr />
          <div className={style.tabsWrap}>
            <Link
              to="/orders/pending"
              className={classNames({
                [style.active]: location.pathname.includes('pending'),
              })}
            >
              {pendingTitle}
            </Link>
            <Link
              to="/orders/confirmed"
              className={classNames({
                [style.active]: location.pathname.includes('confirmed'),
              })}
            >
              {completedTitle}
            </Link>
          </div>
          <Space multiplier={1} />
          <Outlet />
        </Card>
      </div>
    </>
  );
}
