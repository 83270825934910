import {useMemo} from 'react';
import {Country} from '../../entities';
import {intls} from '@qempo.io/web-common';
import {AddressInfo, getAddressInfo} from './address-info';

export type AddressLabel =
  | 'firstName'
  | 'lastName'
  | 'address1'
  | 'address2'
  | 'admin1'
  | 'admin2'
  | 'admin3'
  | 'zipCode'
  | 'phone';

export function useAddressLabel(
  country: Country
): Record<AddressLabel, string> {
  const [
    firstName,
    lastName,
    address1,
    address2,
    admin1,
    admin2,
    admin3,
    zipCode,
    phone,
  ] = intls([
    {
      id: 'address.firstName',
    },
    {
      id: 'address.lastName',
    },
    {
      id: 'address.address1',
    },
    {
      id: 'address.address2',
    },
    {
      id: `address.admin1.${country}`,
    },
    {
      id: `address.admin2.${country}`,
    },
    {
      id: `address.admin3.${country}`,
    },
    {
      id: 'address.zip',
    },
    {
      id: 'address.phone',
    },
  ]);

  const addressInfo = useMemo<AddressInfo | undefined>(
    () => getAddressInfo(country),
    [country]
  );

  return useMemo(() => {
    const l: Record<AddressLabel | string, string> = {
      firstName,
      lastName,
      address1,
      address2,
      zipCode,
      phone,
    };

    if (addressInfo) {
      if (addressInfo.hasAdmin1) l.admin1 = admin1;
      if (addressInfo.hasAdmin2) l.admin2 = admin2;
      if (addressInfo.hasAdmin3) l.admin3 = admin3;
    }

    return l;
  }, [addressInfo]);
}
