import {Category} from '../../../entities';
import {useSelector} from 'react-redux';
import {RootState} from '../../index';
import {useEffect, useState} from 'react';

export function useCategory(
  categoryId: string | undefined
): Category | undefined {
  const [category, setCategory] = useState<Category | undefined>();
  const categories = useSelector<RootState, Category[] | undefined>(
    (s) => s.meta.categories
  );

  useEffect(() => {
    if (categories) {
      setCategory(categories.find((c) => c.id === categoryId));
    }
  }, [categories]);

  return category;
}

export function useCategoryTranslation(category: Category | undefined): string {
  const [translation, setTranslation] = useState('');
  const lang = useSelector<RootState, string>((s) => s.locale.lang);

  useEffect(() => {
    if (category) {
      const t = category.translations.find(
        (t) => t.language.code === lang.toUpperCase()
      );
      if (t) setTranslation(t.name);
    }
  }, [category, lang]);

  return translation;
}
