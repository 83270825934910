import {postCreator, getCreator} from '@qempo.io/web-common/http';
import environment from '../../environment';
import {Traveler, User} from '../../entities';

export const endpoints = Object.freeze({
  LOGIN: `${environment.baseApiUrl}usher/auth/user/login`,
  LOGIN_FACEBOOK: `${environment.baseApiUrl}usher/auth/user/login/facebook`,
  GET_SESSION: `${environment.baseApiUrl}usher/auth/user/session`,
  SIGNUP: `${environment.baseApiUrl}usher/auth/user/signup`,
  GET_TRAVELER: `${environment.baseApiUrl}usher/auth/traveler/:travelerId`,
});

export const loginApi = postCreator<User>(endpoints.LOGIN);
export const getSessionApi = getCreator<User>(endpoints.GET_SESSION);
export const loginFacebookApi = postCreator<User>(endpoints.LOGIN_FACEBOOK);
export const signUpApi = postCreator<User>(endpoints.SIGNUP);
export const getTravelerApi = getCreator<Traveler>(endpoints.GET_TRAVELER);
