import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Order as OrderEntity} from '../../../../entities';
import {useSelector} from 'react-redux';
import {RootState} from '../../../index';
import {formatDate, Space} from '@qempo.io/web-common';
import {ProfileMini} from '../../../../elements/ProfileMini';
import {SummaryNamePrice} from '../../../order/components/Summary';
import {Link} from 'react-router-dom';
import {TitleBox} from '../../../../elements/TitleBox';

import style from './style.module.scss';

type Props = {
  order: OrderEntity;
};

export default function Order({order}: Props) {
  const lang = useSelector<RootState, string>((s) => s.locale.lang);

  return (
    <Link to={`/order/${order.id}`} className={style.linkWrap}>
      <TitleBox
        title={formatDate(new Date(order.createdAt), 'dd MMMM yyyy', lang)}
      >
        {order.items.map((i) => (
          <Fragment key={i.id}>
            <SummaryNamePrice name={i.name} price={i.price} />
            <Space multiplier={2} />
          </Fragment>
        ))}
        <hr />
        <Space multiplier={2} />
        {order.flight && (
          <ProfileMini
            travelerId={order.flight.travelerId}
            subtitle1={formatDate(
              new Date(order.flight.date),
              'dd MMMM, yyyy',
              lang
            )}
            subtitle2={`${order.flight.departureAdmin1.name} - ${order.flight.arrivalAdmin1.name}`}
          />
        )}
      </TitleBox>
    </Link>
  );
}

Order.propTypes = {
  order: PropTypes.object.isRequired,
};
