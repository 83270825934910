import React from 'react';
import PropTypes from 'prop-types';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormClearErrors,
  UseFormSetValue,
  UseFormTrigger,
} from 'react-hook-form';
import {intl} from '@qempo.io/web-common/utils/i18n';
import {Input} from '@qempo.io/web-common/elements';
import {intls, setPrecision, Space, urlRegex} from '@qempo.io/web-common';

import style from './style.module.scss';
import {Pricing} from '../../../../entities';
import {formatPricePrecision} from '../../../../utils/string';

type Props = {
  control: Control;
  clearErrors: UseFormClearErrors<any>;
  setValue: UseFormSetValue<any>;
  trigger: UseFormTrigger<any>;
  errors: FieldErrors;
  disabled?: boolean;
  pricing?: Pricing;
  isFetchingPricing?: boolean;
};

export function ProductForm(props: Props) {
  const {control, clearErrors, setValue, trigger, errors, disabled, pricing} =
    props;

  const [importationIntl, lastmileIntl, qempoIntl, totalIntl] = intls([
    {id: 'quote.import'},
    {id: 'quote.lastmile'},
    {id: 'quote.qempo'},
    {id: 'quote.total'},
  ]);

  return (
    <>
      <form className={style.productForm}>
        <Controller
          name="product.name"
          control={control}
          defaultValue=""
          rules={{
            required: intl({
              id: 'lite.reserve.menu.product.name.error.required',
            }),
          }}
          render={({field}) => (
            <Input
              id={field.name}
              label={intl({id: 'lite.reserve.menu.product.name'})}
              name={field.name}
              onChange={field.onChange}
              onFocus={() => clearErrors(field.name)}
              value={field.value}
              error={errors.name}
              disabled={disabled}
            />
          )}
        />
        <Space multiplier={2} />
        <Controller
          name="product.category"
          control={control}
          defaultValue=""
          rules={{
            required: intl({
              id: 'lite.reserve.menu.product.category.error.required',
            }),
          }}
          render={({field}) => (
            <Input
              id={field.name}
              label={intl({id: 'lite.reserve.menu.product.category'})}
              name={field.name}
              onChange={field.onChange}
              onFocus={() => clearErrors(field.name)}
              value={field.value}
              disabled
            />
          )}
        />
        <Space multiplier={2} />
        <Controller
          name="product.url"
          control={control}
          defaultValue=""
          rules={{
            required: intl({
              id: 'lite.reserve.menu.product.url.error.required',
            }),
            pattern: {
              value: urlRegex,
              message: intl({
                id: 'lite.reserve.menu.product.url.error.format',
              }),
            },
          }}
          render={({field}) => (
            <Input
              id={field.name}
              label={intl({id: 'lite.reserve.menu.product.url'})}
              name={field.name}
              onChange={field.onChange}
              onFocus={() => clearErrors(field.name)}
              onBlur={() => field.value && trigger(field.name)}
              value={field.value}
              error={errors.url}
              disabled={disabled}
            />
          )}
        />
        <Space multiplier={2} />
        <Controller
          name="product.price"
          control={control}
          defaultValue=""
          rules={{
            required: intl({
              id: 'lite.reserve.menu.product.price.error.required',
            }),
          }}
          render={({field}) => (
            <Input
              id={field.name}
              label={intl({id: 'lite.reserve.menu.product.price'})}
              name={field.name}
              onChange={field.onChange}
              onFocus={() => clearErrors(field.name)}
              onBlur={() => {
                if (field.value) {
                  setValue(field.name, setPrecision(field.value));
                  return trigger(field.name);
                }
              }}
              value={field.value}
              error={errors.price}
              disabled={disabled}
              prefix={<span>$</span>}
              cleaveOptions={{
                numeral: true,
                numeralThousandsGroupStyle: 'thousand',
                numeralPositiveOnly: false,
                numeralDecimalScale: 2,
              }}
            />
          )}
        />
        <Space multiplier={2} />
        <div className={style.quoteWrap}>
          <div className={style.quoteLine}>
            <span>{importationIntl}</span>
            {!pricing && <span className={style.price}>$--.--</span>}
            {pricing && (
              <span className={style.price}>
                {formatPricePrecision(pricing.commission, '$')}
              </span>
            )}
          </div>
          <Space multiplier={1} />
          <div className={style.quoteLine}>
            <span>{qempoIntl}</span>
            {!pricing && <span className={style.price}>$--.--</span>}

            {pricing && (
              <span className={style.price}>
                {formatPricePrecision(pricing.qempo, '$')}
              </span>
            )}
          </div>
          <Space multiplier={1} />
          <div className={style.quoteLine}>
            <span>{lastmileIntl}</span>
            {!pricing && <span className={style.price}>$--.--</span>}

            {pricing && (
              <span className={style.price}>
                {formatPricePrecision(pricing.lastMile, '$')}
              </span>
            )}
          </div>
          <Space multiplier={1} />
          <hr />
          <Space multiplier={1} />
          <div className={style.quoteLine}>
            <span>
              <strong>{totalIntl}</strong>
            </span>
            {!pricing && <span className={style.price}>$--.--</span>}
            {pricing && (
              <span className={style.price}>
                <strong>{formatPricePrecision(pricing.total, '$')}</strong>
              </span>
            )}
          </div>
        </div>
      </form>
    </>
  );
}

ProductForm.propTypes = {
  control: PropTypes.object.isRequired,
  clearErrors: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  trigger: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  pricing: PropTypes.object,
  isFetchingPricing: PropTypes.bool,
};
