import React, {MutableRefObject} from 'react';
import PropTypes from 'prop-types';
import {useMessage} from '@qempo.io/web-common';
import {PaymentProcessorReceiveEvent} from '../../../../entities';
import env from '../../../../environment';

type Props = {
  selectedIframe: MutableRefObject<HTMLIFrameElement | null>;
  open: boolean;
  onReady: () => void;
  onComplete: (processorData: Record<string, string>) => void;
  onError: () => void;
  onClose: () => void;
};

export function Culqi(props: Props) {
  const {selectedIframe, open, onReady, onComplete, onError, onClose} = props;

  useMessage(
    PaymentProcessorReceiveEvent.READY_TO_SETUP,
    env.allowedTargetOrigins,
    () => {
      onReady();
    }
  );

  useMessage<{token: string}>(
    PaymentProcessorReceiveEvent.CHECKOUT_SUCCESS,
    env.allowedTargetOrigins,
    (event) => {
      onComplete({token: event.data.token});
    }
  );

  useMessage<{token: string}>(
    PaymentProcessorReceiveEvent.CHECKOUT_ERROR,
    env.allowedTargetOrigins,
    () => {
      onError();
    }
  );

  useMessage(
    PaymentProcessorReceiveEvent.CLOSE,
    env.allowedTargetOrigins,
    () => {
      onClose();
    }
  );

  return (
    <iframe
      ref={selectedIframe}
      src={`${env.paymentGatewayUrl}?processor=CULQI`}
      frameBorder="0"
      style={
        !open
          ? {display: 'none'}
          : {
              display: 'block',
              position: 'fixed',
              top: 0,
              bottom: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              zIndex: 999,
            }
      }
    />
  );
}

Culqi.propTypes = {
  selectedIframe: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onReady: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
