import React from 'react';
import {Helmet} from 'react-helmet-async';
import {intl} from '@qempo.io/web-common/utils/i18n';
import {useSelector} from 'react-redux';
import {RootState} from '../../../index';

export function SignUpHelmet(): JSX.Element {
  const baseUrl = useSelector((state: RootState) => state.locale.baseUrl);
  const signupTitle = intl({id: 'auth.signup.title'});

  return (
    <Helmet>
      <title>{signupTitle}</title>
      <meta property="og:title" content={signupTitle} />
      <meta name="twitter:title" content={signupTitle} />
      <link rel="canonical" href={`${baseUrl}signup/`} />
    </Helmet>
  );
}
