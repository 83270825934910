import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '../index';
import {actions as authActions, AuthState} from './duck';
import {endpoints} from './api';
import {useActions} from '@qempo.io/web-common/redux';
import {Traveler, User} from '../../entities';
import {getTravelerApi} from './api';
import {buildRoute} from '@qempo.io/web-common';

export function useSession(): User | undefined {
  const {getSession} = useActions({...authActions});
  const {user, initialSession, isGettingSession} = useSelector<
    RootState,
    AuthState
  >(({auth}) => auth);

  useEffect(() => {
    if (!user && !initialSession && !isGettingSession) {
      getSession();
    }
  }, [getSession, user, initialSession, isGettingSession]);

  return user;
}

export function useTraveler(travelerId?: string): Traveler | undefined {
  const [traveler, setTraveler] = useState<Traveler>();

  useEffect(() => {
    if (!traveler && travelerId) {
      const subscription = getTravelerApi(undefined, {
        route: buildRoute(endpoints.GET_TRAVELER, [travelerId]),
      }).subscribe(setTraveler);

      return () => {
        subscription.unsubscribe();
      };
    }
  }, [travelerId, traveler]);

  return traveler;
}
