import auth from '../modules/auth/i18n';
import home from '../modules/home/i18n';
import liteReserve from '../modules/lite-reserve/i18n';
import addressForm from '../modules/address/i18n';
import checkout from '../modules/checkout/i18n';
import order from '../modules/order/i18n';
import orders from '../modules/orders/i18n';

export type IntlMessages = {[key: string]: string};

const messages: {[key: string]: IntlMessages} = {
  en: {
    'qempo.document.title': 'Buy products of all around the world | Qempo',
    'qempo.document.meta.og.title':
      'Buy Worldwide and Earn Money Traveling | Qempo',
    'qempo.document.meta.og.description':
      'Buy unique products from around the world with our Qempo travel community and save time and money. Earn money traveling with the free space of your luggage.',
    'qempo.document.meta.twitter.title':
      'Buy Worldwide and Earn Money Traveling | Qempo',
    'qempo.document.meta.twitter.description':
      'Buy unique products from around the world with our Qempo travel community and save time and money. Earn money traveling with the free space of your luggage.',
    'subnavbar.back': 'Go back',

    'quote.import': 'Import service',
    'quote.lastmile': 'Last mile',
    'quote.qempo': 'Qempo',
    'quote.total': 'Total service',

    ...addressForm.en,
    ...auth.en,
    ...home.en,
    ...liteReserve.en,
    ...checkout.en,
    ...order.en,
    ...orders.en,
  },
  es: {
    'qempo.document.title': 'Compra los mejores productos del mundo | Qempo',
    'qempo.document.meta.og.title':
      'Compra por el Mundo y Gana Dinero Viajando | Qempo',
    'qempo.document.meta.og.description':
      'Compra productos únicos en todo el mundo con nuestra comunidad de viajeros de Qempo y ahorra tiempo y dinero. Gana dinero viajando con el espacio libre de tu equipaje.',
    'qempo.document.meta.twitter.title':
      'Compra por el Mundo y Gana Dinero Viajando | Qempo',
    'qempo.document.meta.twitter.description':
      'Compra productos únicos en todo el mundo con nuestra comunidad de viajeros de Qempo y ahorra tiempo y dinero. Gana dinero viajando con el espacio libre de tu equipaje.',
    'subnavbar.back': 'Regresar',

    'quote.import': 'Servicio de Importación',
    'quote.lastmile': 'Envío',
    'quote.qempo': 'Qempo',
    'quote.total': 'Servicio Total',
    ...addressForm.es,
    ...auth.es,
    ...home.es,
    ...liteReserve.es,
    ...checkout.es,
    ...order.es,
    ...orders.es,
  },
};

export default messages;
