'use strict';

const consoleLogo = require('@qempo.io/web-common/polyfills/console-logo');

if (window) {
  // Promise
  /*if (typeof Promise === 'undefined') {
    require('promise/lib/rejection-tracking').enable();
    window.Promise = require('promise/lib/es6-extensions.js');
  }

  // MatchMedia
  require('matchmedia-polyfill');
  require('matchmedia-polyfill/matchMedia.addListener');*/

  // Console image
  window.console.logo = consoleLogo;

  // localStorage
  /*require('../polyfills/local-storage');
  // Object assign
  Object.assign = require('object-assign');
  // Object keys
  Object.keys = require('../polyfills/object-keys');
  // Object equals
  Object.equals = require('../polyfills/object-equals');
  // Array some
  Array.prototype.some = require('../polyfills/array-some');
  // Array fill
  Array.prototype.fill = require('../polyfills/array-fill');
  // Array every
  Array.prototype.every = require('../polyfills/array-every');
  String.prototype.startsWith = require('../polyfills/string-startsWith');*/
}
