import {postCreator, getCreator} from '@qempo.io/web-common/http';
import environment from '../../environment';
import {Flight, LiteReserve, Pricing, Traveler} from '../../entities';

export const endpoints = Object.freeze({
  FETCH_PRICING: `${environment.baseApiUrl}logistics/pricing/calculate/:pricingId/lite/flight`,
  FETCH_FLIGHT: `${environment.baseApiUrl}logistics/flight/:flightId`,
  CREATE_LITE_RESERVE: `${environment.baseApiUrl}logistics/reserve/lite`,
  FETCH_LITE_RESERVE: `${environment.baseApiUrl}logistics/reserve/lite/:liteReserveId`,
  GET_TRAVELER: `${environment.baseApiUrl}usher/auth/traveler/:travelerId`,
});

export const fetchPricingApi = postCreator<Pricing>(endpoints.FETCH_PRICING);
export const fetchFlightApi = getCreator<Flight>(endpoints.FETCH_FLIGHT);
export const createLiteReserveApi = postCreator<LiteReserve>(
  endpoints.CREATE_LITE_RESERVE
);
export const fetchLiteReserveApi = getCreator<LiteReserve>(
  endpoints.FETCH_LITE_RESERVE
);
export const getTravelerApi = getCreator<Traveler>(endpoints.GET_TRAVELER);
