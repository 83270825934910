import {ApiError} from '@qempo.io/web-common/http';
import {Order} from '../../entities';
import {GenericAction, GenericActionPayload} from '@qempo.io/web-common/redux';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {combineEpics, Epic, ofType} from 'redux-observable';
import {catchError, map, switchMap} from 'rxjs/operators';
import {getOrdersApi} from './api';
import {of} from 'rxjs';
import {RootState} from '../index';

export type OrdersState = {
  orders?: Order[];
  isFetchingOrders?: boolean;
  fetchOrdersError?: ApiError;
};

export type OrdersAction = GenericActionPayload & {
  orders?: Order[];
  state?: string;
  error?: ApiError;
};

export type OrderEpic = Epic<
  GenericAction<OrdersAction>,
  GenericAction<OrdersAction>,
  RootState
>;

export const initialState: OrdersState = {};

const slice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchOrders: (s, action: PayloadAction<OrdersAction>) => {
      s.isFetchingOrders = true;
    },
    fetchOrdersSuccess: (
      s,
      {payload: {orders}}: PayloadAction<OrdersAction>
    ) => {
      s.orders = orders;
      s.isFetchingOrders = false;
      s.fetchOrdersError = undefined;
    },
    fetchOrdersError: (s, {payload: {error}}: PayloadAction<OrdersAction>) => {
      s.orders = undefined;
      s.isFetchingOrders = false;
      s.fetchOrdersError = error;
    },
    resetState: () => initialState,
  },
});

export default slice.reducer;

const {fetchOrders, fetchOrdersSuccess, fetchOrdersError, resetState} =
  slice.actions;

export const actions = {fetchOrders, resetState};

const fetchOrdersEpic: OrderEpic = (action$) =>
  action$.pipe(
    ofType(fetchOrders),
    switchMap(({payload: {state}}) =>
      getOrdersApi({state: state as string}).pipe(
        map((orders) => fetchOrdersSuccess({orders})),
        catchError((error: ApiError) => of(fetchOrdersError({error})))
      )
    )
  );

export const epics = combineEpics(fetchOrdersEpic);
