import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Order} from '../../../../entities';
import {ProfileMini} from '../../../../elements/ProfileMini';
import {useTraveler} from '../../../auth/hooks';
import {useSelector} from 'react-redux';
import {RootState} from '../../../index';
import {formatDate} from '@qempo.io/web-common';

type Props = {
  order?: Order;
};

export function LogisticPartnerProfile(props: Props) {
  const {order} = props;

  const traveler = useTraveler(order && order.flight?.travelerId);
  const lang = useSelector<RootState, string>((s) => s.locale.lang);

  const title = useMemo(() => {
    if (traveler) {
      return `${traveler.firstName} ${traveler.lastName}`;
    }
  }, [traveler]);

  const image = useMemo(() => {
    if (traveler) {
      return traveler.image;
    }
  }, [traveler]);

  const subtitle1 = useMemo(() => {
    if (order && order.flight) {
      return formatDate(new Date(order.flight.date), 'dd MMMM, yyyy', lang);
    }
  }, [order]);

  const subtitle2 = useMemo(() => {
    if (order && order.flight) {
      return `${order.flight.departureAdmin1.name} - ${order.flight.arrivalAdmin1.name}`;
    }
  }, [order]);

  return (
    <ProfileMini
      title={title}
      image={image}
      subtitle1={subtitle1}
      subtitle2={subtitle2}
    />
  );
}

LogisticPartnerProfile.propTypes = {
  order: PropTypes.object,
};
