import React from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter as Router} from 'react-router-dom';
import {configureStore} from '@qempo.io/web-common/redux';
import {
  rootReducer,
  rootEpic,
  buildEpicMiddleware,
  defaultInitialState,
  RootState,
} from './modules';
import App from './App';
import {HelmetProvider} from 'react-helmet-async';

let initialState: RootState = defaultInitialState;

if (window && (window as any).__INITIAL_STATE__) {
  initialState = (window as any).__INITIAL_STATE__;
}

const epicMiddleware = buildEpicMiddleware();
const store = configureStore(rootReducer, epicMiddleware, initialState, {
  serializableCheck: {
    ignoredActions: ['order/registerItems'],
  },
});

epicMiddleware.run(rootEpic as any);

export default (
  <React.StrictMode>
    <Router>
      <Provider store={store}>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </Provider>
    </Router>
  </React.StrictMode>
);
