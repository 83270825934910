import {Level, getLevelByName, LevelName} from '@qempo.io/web-common/logger';
import {trailSlash} from '@qempo.io/web-common/utils/string';

export type AppEnvironment = 'local' | 'development' | 'staging' | 'production';

export interface Environment {
  env: AppEnvironment;
  logLevel: Level;
  version: string;
  baseApiUrl: string;
  facebook: string;
  publicUrl: string;
  paymentGatewayUrl: string;
  allowedTargetOrigins: string[];
  paypal: string;
}

const env: Environment = {
  env: (process.env.REACT_APP_ENV as AppEnvironment) || 'local',
  logLevel: getLevelByName(process.env.REACT_APP_LOG_LEVEL as LevelName),
  version: process.env.REACT_APP_VERSION || '',
  // If prerender, probably you want to put the public ALB DNS name
  baseApiUrl: process.env.REACT_APP_BASE_API_URL || '/api/',
  facebook: '430365700492582',
  publicUrl: trailSlash(process.env.PUBLIC_URL),
  paymentGatewayUrl: 'http://checkout.local.qempo.io/',
  allowedTargetOrigins: ['http://checkout.local.qempo.io/'],
  paypal:
    'Aa7fN-vot0j7E9cNa2hya7nfn7IWoAhqO9c34q5lApO2yn35XRrFS-Vtgkhe4cTJTZ6P5_CB03mH4Q1W',
};

if (
  process.env.REACT_APP_ENV === 'development' ||
  process.env.REACT_APP_ENV === 'staging'
) {
  env.facebook = '485875588484303';
  env.paymentGatewayUrl = 'https://checkout.dev.qempo.io/';
  env.allowedTargetOrigins = [
    'https://checkout.dev.qempo.io/',
    'https://checkout.staging.qempo.io/',
  ];
} else if (process.env.REACT_APP_ENV === 'production') {
  env.facebook = '225598827837226';
  env.logLevel = Level.INFO;
  env.paymentGatewayUrl = 'https://checkout.qempo.io/';
  env.allowedTargetOrigins = ['https://checkout.qempo.io/'];
  env.paypal =
    'AYG9IZsTnBPaibtYefbdiE4q8E9o-1IhOlYCHEch3-ulnL8dA3QRImVIkcFgdt9KLv5OmtRN9Gy7n4Fq';
}

if (process.env.REACT_APP_ENV !== 'production') {
  // eslint-disable-next-line no-console
  console.log('env', env);
}

export default env;
