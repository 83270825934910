import React, {ReactNode} from 'react';
import PropTypes from 'prop-types';
import Rodal from 'rodal';
import {Helmet} from 'react-helmet-async';

import './modal.css';
import style from './modal.module.scss';

export type Props = {
  open: boolean;
  width?: number;
  height?: number;
  closeOnEsc?: boolean;
  onClose: () => void;
  children: ReactNode;
};

const DEFAULT_WIDTH = 450;
const DEFAULT_HEIGHT = 250;

export function Modal(props: Props) {
  const {
    open,
    width = DEFAULT_WIDTH,
    height = DEFAULT_HEIGHT,
    closeOnEsc = true,
    onClose,
    children,
  } = props;

  return (
    <>
      {open && (
        <Helmet>
          <style type="text/css">{`
          body {
              overflow: hidden!important;
          }
        `}</style>
        </Helmet>
      )}
      <Rodal
        visible={open}
        duration={120}
        customStyles={{
          minHeight: height,
          height: 'auto',
          borderRadius: '5px',
          maxWidth: width,
          width: '100%',
        }}
        onClose={onClose}
        closeOnEsc={closeOnEsc}
        className="rodal-custom"
      >
        <div className={style.modalWrap}>{children}</div>
      </Rodal>
    </>
  );
}

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  closeOnEsc: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};
