import React, {ReactNode, useEffect} from 'react';
import PropTypes from 'prop-types';
import {IntlProvider} from 'react-intl';
import {connect} from 'react-redux';
import i18nMessages from '../../i18n';
import {actions as localeActions} from './duck';
import {Country} from '../../entities';
import {useActions} from '@qempo.io/web-common/redux';
import {trailSlash} from '@qempo.io/web-common/utils/string';
import {getDomainCountry} from '@qempo.io/web-common/utils/locale';

import type {RootState} from '../index';

type Props = {
  lang?: string;
  baseUrl?: string;
  domainCountry?: Country;
  children: ReactNode;
};

function Locale(props: Props): JSX.Element {
  const {lang, domainCountry, baseUrl, children} = props;

  const {setDomainCountry, setBaseUrl} = useActions({...localeActions});

  // Initialize baseUrl
  useEffect(() => {
    if (!baseUrl && setBaseUrl && document) {
      // Make sure it ends with trailing "/"
      setBaseUrl({baseUrl: trailSlash(document.location.origin)});
    }
  }, [baseUrl, setBaseUrl]);

  // Initialize domain
  useEffect(() => {
    if (!domainCountry && setDomainCountry && document) {
      setDomainCountry({
        country: getDomainCountry(document.location.host) as Country,
      });
    }
  }, [domainCountry, setDomainCountry]);

  return (
    <IntlProvider locale={lang || 'es'} messages={i18nMessages[lang || 'es']}>
      {children}
    </IntlProvider>
  );
}

Locale.propTypes = {
  lang: PropTypes.string,
  baseUrl: PropTypes.string,
  domainCountry: PropTypes.string,
} as any;

export default connect((state: RootState) => ({
  lang: state.locale.lang,
  baseUrl: state.locale.baseUrl,
  domainCountry: state.locale.domainCountry,
}))(Locale);
