import {Country} from '../../entities';

export type AddressInfo = {
  hasAdmin1: boolean;
  hasAdmin2: boolean;
  hasAdmin3: boolean;
  phonePrefix: string;
  hasIdNumber: boolean;
};

const addressInfoCountry: {[key: string]: AddressInfo} = {
  PE: {
    hasAdmin1: true,
    hasAdmin2: true,
    hasAdmin3: true,
    phonePrefix: '+51',
    hasIdNumber: true,
  },
  US: {
    hasAdmin1: true,
    hasAdmin2: true,
    hasAdmin3: false,
    phonePrefix: '+1',
    hasIdNumber: false,
  },
};

export function getAddressInfo(country: Country): AddressInfo | undefined {
  return addressInfoCountry[country];
}
