import React, {useCallback, useEffect} from 'react';
import {FloatingBottom, intls, Space} from '@qempo.io/web-common';
import {useSelector} from 'react-redux';
import {RootState} from '../../../index';
import {OrderState} from '../../duck';
import RegisterItem from './components/RegisterItem';
import {useForm} from 'react-hook-form';
import classNames from 'classnames';
import {useActions} from '@qempo.io/web-common/redux';
import {actions} from '../../duck';
import {RegisterItemApiRequest} from '../../api';
import {AlertType, useGlobalMessageAlert} from '@qempo.io/web-common/elements';

import style from './style.module.scss';
import buttonStyle from '@qempo.io/web-common/assets/styles/button.module.scss';

export default function OrderRegister() {
  const {setGlobalMessageAlert} = useGlobalMessageAlert();
  const {order, isRegisteringItems, registerItemsSuccess, registerItemsError} =
    useSelector<RootState, OrderState>((s) => s.order);

  const {registerItems} = useActions(actions);

  const {
    control,
    clearErrors,
    handleSubmit,
    resetField,
    setError,
    formState: {errors},
  } = useForm({
    mode: 'onSubmit',
  });

  const [title, buttonLabel, successLabel, errorLabel] = intls([
    {id: 'order.register.title'},
    {id: 'order.register.button'},
    {id: 'order.register.success'},
    {id: 'order.register.error'},
  ]);

  const onSubmit = useCallback(
    (registerItemsRequest: Record<string, RegisterItemApiRequest>) => {
      if (order) {
        registerItems({
          orderId: order.id,
          registerItemsRequest: Object.keys(registerItemsRequest).map(
            (k) => registerItemsRequest[k]
          ),
        });
      }
    },
    [order]
  );

  useEffect(() => {
    if (registerItemsError) {
      setGlobalMessageAlert({
        type: AlertType.ERROR,
        ttl: 7000,
        content: errorLabel,
      });
    }
  }, [registerItemsError, setGlobalMessageAlert, successLabel]);

  useEffect(() => {
    if (registerItemsSuccess) {
      setGlobalMessageAlert({
        type: AlertType.SUCCESS,
        ttl: 7000,
        content: successLabel,
      });
    }
  }, [registerItemsSuccess, setGlobalMessageAlert, successLabel]);

  return (
    <>
      <h1>{title}</h1>
      <Space multiplier={3} />
      <hr />
      <Space multiplier={3} />
      {order && order.items.length && (
        <div className={style.itemsWrap}>
          {order.items.map((i) => (
            <div key={i.id}>
              <RegisterItem
                orderItem={i}
                control={control}
                clearErrors={clearErrors}
                resetField={resetField}
                setError={setError}
                errors={errors}
                disabled={isRegisteringItems}
              />
              <Space multiplier={3} />
              <hr />
              <Space multiplier={3} />
            </div>
          ))}
        </div>
      )}
      <button
        className={classNames(
          buttonStyle.btn,
          buttonStyle.blue,
          style.registerButton
        )}
        onClick={handleSubmit(onSubmit)}
        disabled={isRegisteringItems}
      >
        {buttonLabel}
      </button>
      <FloatingBottom className={style.floatingBottom}>
        <button
          className={classNames(buttonStyle.btn, buttonStyle.blue)}
          onClick={handleSubmit(onSubmit)}
          disabled={isRegisteringItems}
        >
          {buttonLabel}
        </button>
      </FloatingBottom>
    </>
  );
}
