import React, {Fragment, useEffect} from 'react';
import {Space, useActions} from '@qempo.io/web-common';
import {actions as ordersActions, OrdersState} from '../../duck';
import {useSelector} from 'react-redux';
import {RootState} from '../../../index';
import Order from '../../components/Order';

export default function OrdersCompleted() {
  const {fetchOrders, resetState} = useActions(ordersActions);

  const {orders, isFetchingOrders, fetchOrdersError} = useSelector<
    RootState,
    OrdersState
  >((s) => s.orders);

  useEffect(() => {
    if (!orders && !isFetchingOrders && !fetchOrdersError) {
      fetchOrders({state: 'COMPLETED'});
    }
  }, [orders, isFetchingOrders, fetchOrders]);

  useEffect(() => {
    return () => {
      resetState();
    };
  }, [resetState]);

  return (
    <>
      {orders &&
        orders.map((o) => (
          <Fragment key={o.id}>
            <Order order={o} />
            <Space multiplier={1} />
          </Fragment>
        ))}
    </>
  );
}
