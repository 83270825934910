import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {intl, Space} from '@qempo.io/web-common';
import {CategorySelect} from '../CategorySelect';
import {Modal} from '../../../../elements/Modal';
import {Category} from '../../../../entities';

import buttonStyle from '@qempo.io/web-common/assets/styles/button.module.scss';

type Props = {
  open: boolean;
  onClose: () => void;
  categories?: Category[];
  categoryId?: string;
  handleSelectCategory: (categoryId: string) => void;
};

export function CategoryModal(props: Props) {
  const {open, onClose, categories, categoryId, handleSelectCategory} = props;

  const title = intl({id: 'home.lite.modal.title'});
  const button = intl({id: 'home.lite.modal.button'});

  const [selectedCategoryId, setSelectedCategoryId] = useState<string>();

  useEffect(() => {
    if (open && categoryId) {
      setSelectedCategoryId(categoryId);
    }
  }, [open, categoryId]);

  const handleClickConfirm = () => {
    if (selectedCategoryId) {
      handleSelectCategory(selectedCategoryId);
      onClose();
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <h3>{title}</h3>
      <Space multiplier={3} />
      {categories &&
        categories.map((c) => (
          <CategorySelect
            key={c.id}
            selected={c.id === selectedCategoryId}
            category={c}
            onClick={() => setSelectedCategoryId(c.id)}
          />
        ))}
      <Space multiplier={1} />
      <button
        className={classNames(buttonStyle.btn, buttonStyle.blue)}
        disabled={!selectedCategoryId}
        onClick={handleClickConfirm}
      >
        {button}
      </button>
    </Modal>
  );
}

CategoryModal.prototype = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  categories: PropTypes.array,
  categoryId: PropTypes.number,
  handleSelectCategory: PropTypes.func.isRequired,
};
