import environment from '../../../environment';
import {logger} from '../../../logger';

export type FBLoginResponse = {
  status: 'connected';
  authResponse: {
    accessToken: string;
    expiresIn: number;
    signedRequest: string;
    userID: string;
  };
};

const permissions = [
  'email',
  'public_profile',
  'user_age_range',
  'user_birthday',
  'user_friends',
  'user_gender',
  'user_hometown',
  'user_likes',
  'user_location',
];

export function FBinit(): void {
  if (window) {
    // Configure Facebook
    (window as any).fbAsyncInit = () => {
      (window as any).fbInitialized = true;
      (window as any).FB.init({
        appId: environment.facebook,
        autoLogAppEvents: false,
        xfbml: false,
        version: 'v13.0',
      });
    };
  }
}

export function FBlogin(): Promise<FBLoginResponse> {
  if (!window) return Promise.reject(new Error('window is not defined'));

  if (!(window as any).fbInitialized) {
    (window as any).fbAsyncInit();
  }

  return new Promise<FBLoginResponse>((resolve, reject) => {
    if (!(window as any).FB) return reject(new Error('FB is not defined'));
    return (window as any).FB.login(
      (response: FBLoginResponse) => {
        logger.debug(response);
        resolve(response);
      },
      {
        scope: permissions.join(', '),
      }
    );
  });
}
