import {ApiError} from '@qempo.io/web-common/http';
import {Order, PaymentMethods} from '../../entities';
import {GenericAction, GenericActionPayload} from '@qempo.io/web-common/redux';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {of} from 'rxjs';
import {combineEpics, Epic, ofType} from 'redux-observable';
import {switchMap, map, catchError} from 'rxjs/operators';
import {
  endpoints,
  checkoutFromLiteReserveApi,
  getPaymentMethodsApi,
} from './api';
import {buildRoute} from '@qempo.io/web-common';
import {RootState} from '../index';

export type CheckoutState = {
  isFetchingPaymentMethods?: boolean;
  paymentMethods?: PaymentMethods;
  fetchPaymentMethodError?: ApiError;
  isCheckingOut?: boolean;
  checkOutError?: ApiError;
  order?: Order;
};

export type CheckoutAction = GenericActionPayload & {
  country?: string;
  paymentMethods?: PaymentMethods;
  processorData?: Record<string, string>;
  liteReserveId?: string;
  paymentMethod?: string;
  paymentProcessor?: string;
  order?: Order;
  error?: ApiError;
};

type CheckoutEpic = Epic<
  GenericAction<CheckoutAction>,
  GenericAction<CheckoutAction>,
  RootState
>;

export const initialState: CheckoutState = {};

const slice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchPaymentMethods: (s, action: PayloadAction<CheckoutAction>) => {
      s.isFetchingPaymentMethods = true;
      s.paymentMethods = undefined;
      s.fetchPaymentMethodError = undefined;
    },
    fetchPaymentMethodsSuccess: (
      s,
      {payload: {paymentMethods}}: PayloadAction<CheckoutAction>
    ) => {
      s.isFetchingPaymentMethods = false;
      s.paymentMethods = paymentMethods;
      s.fetchPaymentMethodError = undefined;
    },
    fetchPaymentMethodsError: (
      s,
      {payload: {error}}: PayloadAction<CheckoutAction>
    ) => {
      s.isFetchingPaymentMethods = false;
      s.paymentMethods = undefined;
      s.fetchPaymentMethodError = error;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    checkOut: (s, action: PayloadAction<CheckoutAction>) => {
      s.isCheckingOut = true;
      s.checkOutError = undefined;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    checkOutSuccess: (s, {payload}: PayloadAction<CheckoutAction>) => {
      s.order = payload.order;
      s.isCheckingOut = false;
    },
    checkOutError: (s, {payload: {error}}: PayloadAction<CheckoutAction>) => {
      s.isCheckingOut = false;
      s.checkOutError = error;
    },
    resetState: () => initialState,
  },
});

export default slice.reducer;

const {
  fetchPaymentMethods,
  fetchPaymentMethodsSuccess,
  fetchPaymentMethodsError,
  checkOut,
  checkOutSuccess,
  checkOutError,
  resetState,
} = slice.actions;

export const actions = {fetchPaymentMethods, checkOut, resetState};

const fetchPaymentMethodsEpic: CheckoutEpic = (action$) =>
  action$.pipe(
    ofType(fetchPaymentMethods),
    switchMap(({payload: {country}}) =>
      getPaymentMethodsApi(country ? {country} : undefined).pipe(
        map((paymentMethods: PaymentMethods) =>
          fetchPaymentMethodsSuccess({paymentMethods})
        ),
        catchError((error: ApiError) => of(fetchPaymentMethodsError({error})))
      )
    )
  );

const checkOutEpic: CheckoutEpic = (action$) =>
  action$.pipe(
    ofType(checkOut),
    switchMap(
      ({
        payload: {
          country,
          paymentMethod,
          paymentProcessor,
          processorData,
          liteReserveId,
        },
      }) =>
        checkoutFromLiteReserveApi(
          {
            country,
            paymentMethod,
            paymentProcessor,
            processorData,
          },
          {
            route: buildRoute(endpoints.CHECKOUT_FROM_LITE_RESERVE, [
              liteReserveId as string,
            ]),
          }
        ).pipe(
          map((order: Order) => checkOutSuccess({order})),
          catchError((error: ApiError) => of(checkOutError({error})))
        )
    )
  );

export const epics = combineEpics(fetchPaymentMethodsEpic, checkOutEpic);
