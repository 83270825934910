import React, {CSSProperties, useEffect, useMemo, useState} from 'react';
import {PaymentProcessor, PaymentProvider} from '../../entities';
import {intls, useActions} from '@qempo.io/web-common';
import {CheckoutSelectPaymentMethod} from './components/CheckoutSelectPaymentMethod';
import {useProcessorContent} from './processors';
import {useSelector} from 'react-redux';
import {RootState} from '../index';
import {CheckoutState, actions} from './duck';

import paypalLogo from '../../assets/images/checkout/paypal.svg';
import visaLogo from '../../assets/images/checkout/visa.svg';
import amexLogo from '../../assets/images/checkout/amex.svg';
import mastercardLogo from '../../assets/images/checkout/mastercard.svg';
import interbankLogo from '../../assets/images/checkout/interbank.svg';
import bcpLogo from '../../assets/images/checkout/bcp.svg';
import bbvaLogo from '../../assets/images/checkout/bbva.svg';

export type CheckoutConfig = {
  total?: string;
  currency?: string;
  name?: string;
  email?: string;
  disabled?: boolean;
  items?: {name: string; quantity: number; price: string}[];
  lang: string;
  onComplete: (
    provider: PaymentProvider,
    processor: PaymentProcessor,
    processorData?: Record<string, string>
  ) => void;
  onError: () => void;
};

export function useProviderLabels(): {
  [key in PaymentProvider]: {label: string; images: string[]};
} {
  const [paypalIntl, cardIntl, transferIntl, cashIntl] = intls([
    {
      id: 'checkout.provider.paypal',
    },
    {
      id: 'checkout.provider.card',
    },
    {
      id: 'checkout.provider.transfer',
    },
    {
      id: 'checkout.provider.cash',
    },
  ]);

  return {
    CARD: {images: [visaLogo, amexLogo, mastercardLogo], label: cardIntl},
    PAYPAL: {images: [paypalLogo], label: paypalIntl},
    SPREEDLY: {images: [visaLogo, amexLogo, mastercardLogo], label: cardIntl},
    CASH: {images: [interbankLogo, bcpLogo, bbvaLogo], label: cashIntl},
    TRANSFER: {images: [interbankLogo, bcpLogo, bbvaLogo], label: transferIntl},
  };
}

export function useCheckout(config: CheckoutConfig) {
  const {fetchPaymentMethods, resetState} = useActions(actions);

  const {paymentMethods, fetchPaymentMethodError, isFetchingPaymentMethods} =
    useSelector<RootState, CheckoutState>((s) => s.checkout);

  const [paymentMethod, setPaymentMethod] =
    useState<{provider: PaymentProvider; processor: PaymentProcessor}>();

  const {content, button: rawButton} = useProcessorContent(paymentMethod, {
    ...config,
    setPaymentMethod,
  });

  const selectPaymentMethod = useMemo(() => {
    return (
      <CheckoutSelectPaymentMethod
        disabled={config.disabled}
        selectedPaymentMethod={paymentMethod}
        paymentMethods={paymentMethods}
        onSelectPaymentMethod={(provider, processor) => {
          setPaymentMethod({provider, processor});
        }}
      />
    );
  }, [config.disabled, paymentMethods, paymentMethod, setPaymentMethod]);

  useEffect(() => {
    if (
      !paymentMethods &&
      !isFetchingPaymentMethods &&
      !fetchPaymentMethodError
    ) {
      fetchPaymentMethods({country: 'PE'});
    }
  }, [
    paymentMethods,
    isFetchingPaymentMethods,
    fetchPaymentMethodError,
    fetchPaymentMethods,
  ]);

  useEffect(() => {
    return () => {
      resetState();
    };
  }, [resetState]);

  const button = useMemo(() => {
    const disabledStyle: CSSProperties = {
      opacity: 0.6,
      pointerEvents: 'none',
    };

    return <div style={config.disabled ? disabledStyle : {}}>{rawButton}</div>;
  }, [rawButton, config.disabled]);

  return {
    selectPaymentMethod,
    content,
    button,
  };
}
