import React, {useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import {RootState} from '../../../index';
import {User} from '../../../../entities';
import {intl} from '@qempo.io/web-common/utils/i18n';
import {Space} from '@qempo.io/web-common/elements/Space';
import {Avatar} from '../../../../elements/Avatar';

import navbarStyle from './style.module.scss';
import buttonStyle from '@qempo.io/web-common/assets/styles/button.module.scss';
import logo from '../../../../assets/images/logo.svg';
import logoWhite from '../../../../assets/images/logo-white.png';

enum NavbarStyle {
  DEFAULT = 'DEFAULT',
  BLUE400 = 'BLUE400',
}

export function Navbar(): JSX.Element {
  const user = useSelector<RootState, User | undefined>(
    (state) => state.auth.user
  );

  /*function getNavbarStyle(pathname: string): NavbarStyle {
    if (pathname === '/') {
      return NavbarStyle.BLUE400;
    } else {
      return NavbarStyle.DEFAULT;
    }
  }*/

  const [style] = useState(NavbarStyle.DEFAULT);

  const styleOptions = useMemo(() => {
    switch (style) {
      case NavbarStyle.BLUE400:
        return {
          classes: {
            navbar: navbarStyle.blue400,
            button: navbarStyle.default,
          },
          logo: logoWhite,
        };
      case NavbarStyle.DEFAULT:
      default:
        return {
          classes: {
            navbar: navbarStyle.default,
            button: navbarStyle.default,
          },
          logo,
        };
    }
  }, [style]);

  /*useEffect(() => {
    if (location.pathname === '/') {
      setStyle(NavbarStyle.BLUE400);
    } else {
      setStyle(NavbarStyle.DEFAULT);
    }
  }, [location.pathname]);*/

  const navOptions = !user && (
    <div className={navbarStyle.group}>
      <Link
        to="/login"
        className={classNames(
          buttonStyle.btn,
          navbarStyle.button,
          styleOptions.classes.button
        )}
      >
        {intl({id: 'auth.login.title'})}
      </Link>
    </div>
  );

  const navLoggedOptions = user && (
    <div className={navbarStyle.group}>
      <Link
        to="/orders"
        className={classNames(
          buttonStyle.btn,
          navbarStyle.button,
          styleOptions.classes.button
        )}
      >
        Mis pedidos
      </Link>
      <Space multiplier={2} vertical />
      <Avatar
        src={user.image ? user.image.publicUrl : undefined}
        alt={`${user.firstName} ${user.lastName}`}
        className={navbarStyle.avatar}
      />
    </div>
  );

  return (
    <nav
      className={classNames(navbarStyle.navbar, styleOptions.classes.navbar)}
    >
      <div className={navbarStyle.contentWrap}>
        <Link to="/">
          <img
            src={styleOptions.logo}
            className={navbarStyle.logo}
            alt="Qempo"
          />
        </Link>
        {navOptions}
        {navLoggedOptions}
      </div>
    </nav>
  );
}
