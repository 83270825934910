import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Icon} from '@qempo.io/web-common';
import {intl} from '@qempo.io/web-common/utils/i18n';

import style from './style.module.scss';

export function SubNavbar() {
  const navigate = useNavigate();

  const back = intl({id: 'subnavbar.back'});

  return (
    <div className={style.subNavbarWrap}>
      <button className={style.back} onClick={() => navigate(-1)}>
        <div className={style.backCircle}>
          <Icon icon="arrow-left" size={16} />
        </div>
        <span>{back}</span>
      </button>
    </div>
  );
}
