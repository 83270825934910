import React, {ReactNode} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import style from './style.module.scss';

type Props = {
  className?: string;
  mainWrap?: boolean;
  children?: ReactNode;
};

export function Card(props: Props): JSX.Element {
  const {className, mainWrap, children} = props;
  return (
    <div
      className={classNames(
        style.cardWrap,
        {[style.cardMainWrap]: mainWrap},
        className
      )}
    >
      {children}
    </div>
  );
}

Card.propTypes = {
  className: PropTypes.string,
  mainWrap: PropTypes.bool,
  children: PropTypes.node,
};
