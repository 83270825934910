import React from 'react';
import {FloatingBottom, Icon, intls, Space} from '@qempo.io/web-common';
import {OrderState} from '../../duck';
import {useSelector} from 'react-redux';
import {RootState} from '../../../index';
import {useParams} from 'react-router';
import {ToggleMenu} from '../../../../elements/ToggleMenu';
import Summary from '../../components/Summary';
import {LogisticPartnerProfile} from '../../components/LogisticPartnerProfile';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import {ShippingAddress} from '../../components/ShippingAddress';

import style from './style.module.scss';
import buttonStyle from '@qempo.io/web-common/assets/styles/button.module.scss';

export default function OrderCreated() {
  const {orderId} = useParams();
  const {order, invoice} = useSelector<RootState, OrderState>((s) => s.order);

  const [
    title,
    travelerIntl,
    productIntl,
    shippingLabelIntl,
    registerIntl,
    continueIntl,
  ] = intls([
    {id: 'order.paid.title'},
    {id: 'order.menu.traveler'},
    {id: 'order.menu.product'},
    {id: 'order.menu.shipping'},
    {id: 'order.paid.register'},
    {id: 'order.paid.continue'},
  ]);

  const button = (
    <Link
      to={
        order && order.serviceType === 'LITE'
          ? `/order/${orderId}/register`
          : `/order/${orderId}`
      }
      className={classNames(
        buttonStyle.btn,
        buttonStyle.blue,
        style.continueButton
      )}
    >
      {order && order.serviceType === 'LITE' ? registerIntl : continueIntl}
    </Link>
  );

  return (
    <>
      <div className={style.topWrap}>
        <div className={style.checkWrap}>
          <Icon icon="check" size={16} />
        </div>
      </div>
      <Space multiplier={1} />
      <h1>{title}</h1>
      <Space multiplier={1} />
      <h2>{orderId}</h2>
      <Space multiplier={2} />
      <ToggleMenu title={travelerIntl} initialOpen>
        <LogisticPartnerProfile order={order} />
        <Space multiplier={3} />
      </ToggleMenu>
      <ToggleMenu title={productIntl} initialOpen>
        <Summary
          items={
            order
              ? order.items.map((i) => ({
                  name: i.name,
                  quantity: i.quantity,
                  price: i.price,
                }))
              : undefined
          }
          summary={invoice ? invoice.summary : undefined}
        />
        <Space multiplier={3} />
      </ToggleMenu>
      <ToggleMenu title={shippingLabelIntl}>
        {order && <ShippingAddress shippingAddress={order.shippingAddress} />}
        <Space multiplier={2} />
      </ToggleMenu>
      <Space multiplier={4} />
      {button}
      <FloatingBottom className={style.floatingBottom}>{button}</FloatingBottom>
    </>
  );
}
