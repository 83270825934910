import React, {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormClearErrors,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import {intl, Input, Space, Select, intls} from '@qempo.io/web-common';
import parsePhoneNumber from 'libphonenumber-js';
import {Admin1, Admin2, Admin3, Country} from '../../../../entities';
import {listAdmin1Api, listAdmin2Api, listAdmin3Api} from '../../api';
import {AddressInfo, getAddressInfo} from '../../address-info';

import style from './style.module.scss';
import 'cleave.js/dist/addons/cleave-phone.pe';
import classNames from 'classnames';

type Props = {
  formNamePrefix?: string;
  country: Country;
  control: Control;
  clearErrors: UseFormClearErrors<any>;
  setValue: UseFormSetValue<any>;
  trigger: UseFormTrigger<any>;
  watch: UseFormWatch<any>;
  errors: FieldErrors;
  disabled?: boolean;
  expandInputs?: boolean;
};

export function AddressForm(props: Props) {
  const {
    formNamePrefix,
    country,
    control,
    clearErrors,
    setValue,
    watch,
    errors,
    disabled,
    expandInputs,
  } = props;

  const addressInfo = useMemo<AddressInfo | undefined>(
    () => getAddressInfo(country),
    [country]
  );

  const getName = useCallback(
    (field: string) => {
      if (formNamePrefix) return `${formNamePrefix}.${field}`;
      return field;
    },
    [formNamePrefix]
  );

  const admin1Value = watch(getName('admin1'));
  const admin2Value = watch(getName('admin2'));

  const [
    admin1Label,
    admin1ReqLabel,
    admin2Label,
    admin2ReqLabel,
    admin3Label,
    admin3ReqLabel,
    idLabel,
    idReqLabel,
  ] = intls([
    {
      id: `address.admin1.${country}`,
    },
    {
      id: `address.admin1.error.required.${country}`,
    },
    {
      id: `address.admin2.${country}`,
    },
    {
      id: `address.admin2.error.required.${country}`,
    },
    {
      id: `address.admin3.${country}`,
    },
    {
      id: `address.admin3.error.required.${country}`,
    },
    {
      id: `address.idNumber.${country}`,
    },
    {
      id: `address.idNumber.error.required.${country}`,
    },
  ]);

  const [admin1List, setAdmin1List] = useState<Admin1[]>([]);
  const [admin2List, setAdmin2List] = useState<Admin2[]>([]);
  const [admin3List, setAdmin3List] = useState<Admin3[]>([]);

  useEffect(() => {
    if (country) {
      setValue(getName('admin1'), null);
      setAdmin1List([]);
      setValue(getName('admin2'), null);
      setAdmin2List([]);
      setValue(getName('admin3'), null);
      setAdmin3List([]);
      const subs = listAdmin1Api({country}).subscribe(setAdmin1List);
      return () => subs.unsubscribe();
    }
  }, [country, getName]);

  useEffect(() => {
    if (admin1Value) {
      setValue(getName('admin2'), null);
      setAdmin2List([]);
      setValue(getName('admin3'), null);
      setAdmin3List([]);
      const subs = listAdmin2Api({admin1_id: admin1Value.value}).subscribe(
        setAdmin2List
      );
      return () => subs.unsubscribe();
    }
  }, [country, admin1Value]);

  useEffect(() => {
    if (admin2Value) {
      setValue(getName('admin3'), null);
      setAdmin3List([]);
      const subs = listAdmin3Api({admin2_id: admin2Value.value}).subscribe(
        setAdmin3List
      );
      return () => subs.unsubscribe();
    }
  }, [country, admin2Value]);

  const admin1 = useMemo(() => {
    return (
      addressInfo &&
      addressInfo.hasAdmin1 && (
        <Controller
          name={`${getName('admin1')}`}
          control={control}
          defaultValue={null}
          rules={{
            required: admin1ReqLabel,
          }}
          render={({field}) => (
            <Select
              id={field.name}
              label={admin1Label}
              name={field.name}
              onChange={field.onChange}
              value={field.value}
              error={errors.admin1}
              disabled={disabled}
              options={admin1List.map(({id: value, name: label}) => ({
                value,
                label,
              }))}
            />
          )}
        />
      )
    );
  }, [addressInfo, country, admin1List, errors]);

  const admin2 = useMemo(() => {
    return (
      addressInfo &&
      addressInfo.hasAdmin2 && (
        <Controller
          name={`${getName('admin2')}`}
          control={control}
          defaultValue={null}
          rules={{
            required: admin2ReqLabel,
          }}
          render={({field}) => (
            <Select
              id={field.name}
              label={admin2Label}
              name={field.name}
              onChange={field.onChange}
              value={field.value}
              error={errors.admin2}
              disabled={disabled || admin2List.length === 0}
              options={admin2List.map(({id: value, name: label}) => ({
                value,
                label,
              }))}
            />
          )}
        />
      )
    );
  }, [addressInfo, country, admin2List, errors]);

  const admin3 = useMemo(() => {
    return (
      addressInfo &&
      addressInfo.hasAdmin3 && (
        <Controller
          name={`${getName('admin3')}`}
          control={control}
          defaultValue={null}
          rules={{
            required: admin3ReqLabel,
          }}
          render={({field}) => (
            <Select
              id={field.name}
              label={admin3Label}
              name={field.name}
              onChange={field.onChange}
              value={field.value}
              error={errors.admin3}
              disabled={disabled || admin3List.length === 0}
              options={admin3List.map(({id: value, name: label}) => ({
                value,
                label,
              }))}
            />
          )}
        />
      )
    );
  }, [addressInfo, country, admin3List, errors]);

  return (
    <>
      <div
        className={classNames(style.dualWrap, {
          [style.singleWrap]: expandInputs,
        })}
      >
        <div>
          <Controller
            name={`${getName('firstName')}`}
            control={control}
            defaultValue=""
            rules={{
              required: intl({
                id: 'address.firstName.error.required',
              }),
            }}
            render={({field}) => (
              <Input
                id={field.name}
                label={intl({id: 'address.firstName'})}
                name={field.name}
                onChange={field.onChange}
                onFocus={() => clearErrors(field.name)}
                value={field.value}
                error={errors.firstName}
                disabled={disabled}
              />
            )}
          />
        </div>
        <div>
          <Controller
            name={`${getName('lastName')}`}
            control={control}
            defaultValue=""
            rules={{
              required: intl({
                id: 'address.lastName.error.required',
              }),
            }}
            render={({field}) => (
              <Input
                id={field.name}
                label={intl({id: 'address.lastName'})}
                name={field.name}
                onChange={field.onChange}
                onFocus={() => clearErrors(field.name)}
                value={field.value}
                error={errors.lastName}
                disabled={disabled}
              />
            )}
          />
        </div>
      </div>
      <Space multiplier={2} />
      <Controller
        name={`${getName('address1')}`}
        control={control}
        defaultValue=""
        rules={{
          required: intl({
            id: 'address.address1.error.required',
          }),
        }}
        render={({field}) => (
          <Input
            id={field.name}
            label={intl({id: 'address.address1'})}
            name={field.name}
            onChange={field.onChange}
            onFocus={() => clearErrors(field.name)}
            value={field.value}
            error={errors.address1}
            disabled={disabled}
          />
        )}
      />
      <Space multiplier={2} />
      <Controller
        name={`${getName('address2')}`}
        control={control}
        defaultValue=""
        render={({field}) => (
          <Input
            id={field.name}
            label={intl({id: 'address.address2'})}
            name={field.name}
            onChange={field.onChange}
            onFocus={() => clearErrors(field.name)}
            value={field.value}
            error={errors.address2}
            disabled={disabled}
          />
        )}
      />
      <Space multiplier={2} />
      <div
        className={classNames(style.dualWrap, {
          [style.singleWrap]: expandInputs,
        })}
      >
        <div>{admin1}</div>
        <div>{admin2}</div>
      </div>
      <Space multiplier={2} />
      <div
        className={classNames(style.dualWrap, {
          [style.singleWrap]: expandInputs,
        })}
      >
        <div>{admin3}</div>
        <div>
          <Controller
            name={`${getName('idNumber')}`}
            control={control}
            defaultValue=""
            rules={{
              required: idReqLabel,
            }}
            render={({field}) => (
              <Input
                id={field.name}
                label={idLabel}
                name={field.name}
                onChange={field.onChange}
                onFocus={() => clearErrors(field.name)}
                value={field.value}
                error={errors.zip}
                disabled={disabled}
              />
            )}
          />
        </div>
      </div>
      <Space multiplier={2} />
      <div
        className={classNames(style.dualWrap, {
          [style.singleWrap]: expandInputs,
        })}
      >
        <div>
          <Controller
            name={`${getName('zipCode')}`}
            control={control}
            defaultValue=""
            rules={{
              required: intl({
                id: 'address.zip.error.required',
              }),
            }}
            render={({field}) => (
              <Input
                id={field.name}
                label={intl({id: 'address.zip'})}
                name={field.name}
                onChange={field.onChange}
                onFocus={() => clearErrors(field.name)}
                value={field.value}
                error={errors.zip}
                disabled={disabled}
              />
            )}
          />
        </div>
        <div>
          <Controller
            name={`${getName('phone')}`}
            control={control}
            defaultValue=""
            rules={{
              required: intl({
                id: 'address.phone.error.required',
              }),
              validate: (value: string) => {
                const phoneNumber = parsePhoneNumber(value, country);
                if (!phoneNumber || !phoneNumber.isValid()) {
                  return intl({id: 'address.phone.error.format'});
                }
                return true;
              },
            }}
            render={({field}) => (
              <Input
                id={field.name}
                label={intl({id: 'address.phone'})}
                cleaveOptions={{
                  phone: true,
                  phoneRegionCode: country,
                }}
                name={field.name}
                onChange={field.onChange}
                onFocus={() => clearErrors(field.name)}
                value={field.value}
                error={errors.phone}
                prefix={
                  addressInfo && (
                    <span className={style.phonePrefix}>
                      {addressInfo.phonePrefix}
                    </span>
                  )
                }
                disabled={disabled}
              />
            )}
          />
        </div>
      </div>
      <Space multiplier={2} />
    </>
  );
}

AddressForm.propTypes = {
  formNamePrefix: PropTypes.string,
  country: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  clearErrors: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  trigger: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  expandInputs: PropTypes.bool,
};
