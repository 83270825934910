export default {
  en: {
    'lite.reserve.reserve': 'Reservation',
    'lite.reserve.menu.traveler': 'Traveler information',
    'lite.reserve.menu.product': 'Product information',
    'lite.reserve.menu.product.name': 'Name',
    'lite.reserve.menu.product.name.error.required': 'Name is required',
    'lite.reserve.menu.product.category': 'Category',
    'lite.reserve.menu.product.category.error.required': 'Category is required',
    'lite.reserve.menu.product.url': 'URL',
    'lite.reserve.menu.product.url.error.required': 'URL is required',
    'lite.reserve.menu.product.url.error.format': 'Wrong URL format',
    'lite.reserve.menu.product.price': 'Price',
    'lite.reserve.menu.product.price.error.required': 'Price is required',
    'lite.reserve.menu.shipping': 'Shipping information',
    'lite.reserve.reserve.button': 'Reserve',

    'lite.reservation.reservation': 'Reservation with {traveler}',
    'lite.reservation.valid.hours': 'Valid for {time} hours',
    'lite.reservation.valid.minutes': 'Valid for {time} minutes',
    'lite.reservation.paymentMethod': 'Payment method',

    'lite.reservation.warning': 'Si no se registran las compras, Qempo no se hace responsable.',
  },
  es: {
    'lite.reserve.reserve': 'Reservar',
    'lite.reserve.menu.traveler': 'Información de viajero',
    'lite.reserve.menu.product': 'Información de pedido',
    'lite.reserve.menu.product.name': 'Nombre',
    'lite.reserve.menu.product.name.error.required': 'Debes ingresar el nombre',
    'lite.reserve.menu.product.category': 'Categoría',
    'lite.reserve.menu.product.category.error.required': 'Debes ingresar la categoría',
    'lite.reserve.menu.product.url': 'Enlace',
    'lite.reserve.menu.product.url.error.required': 'Debes ingresar el enlace',
    'lite.reserve.menu.product.url.error.format': 'Formato de url incorrecto',
    'lite.reserve.menu.product.price': 'Precio',
    'lite.reserve.menu.product.price.error.required': 'Debes ingresar el precio',
    'lite.reserve.menu.shipping': 'Información de envío',
    'lite.reserve.reserve.button': 'Reservar',

    'lite.reservation.reservation': 'Reserva de {traveler}',
    'lite.reservation.valid.hours': 'Válido por {time} horas',
    'lite.reservation.valid.minutes': 'Válido por {time} minutos',
    'lite.reservation.paymentMethod': 'Método de pago',

    'lite.reservation.warning': 'Si no se registran las compras, Qempo no se hace responsable.',
  },
};
