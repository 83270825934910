import React, {useEffect} from 'react';
import {Outlet, useParams} from 'react-router';
import {Space, useActions} from '@qempo.io/web-common';
import {actions as orderActions, OrderState} from '../duck';
import {useSelector} from 'react-redux';
import {RootState} from '../../index';
import {SubNavbar} from '../../navbar/components/SubNavbar';
import {Card} from '../../../elements/Card';

import style from './style.module.scss';

export default function OrderIndex() {
  const {orderId} = useParams();
  const {fetchOrder, resetState} = useActions(orderActions);
  const {order, isFetchingOrder, fetchOrderError} = useSelector<
    RootState,
    OrderState
  >((s) => s.order);

  useEffect(() => {
    if (!order && !isFetchingOrder && !fetchOrderError) {
      fetchOrder({orderId});
    }
  }, [orderId, order, isFetchingOrder, fetchOrder]);

  useEffect(() => {
    return () => {
      resetState();
    };
  }, [resetState]);

  return (
    <>
      <SubNavbar />
      <div className={style.orderWrap}>
        <Space multiplier={0.5} />
        <Card className={style.cardWrap}>
          <Outlet />
        </Card>
      </div>
    </>
  );
}
