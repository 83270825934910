import React, {ChangeEvent, useCallback, useEffect, useState} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {RootState} from '../../../index';
import {ApiError} from '@qempo.io/web-common/http';
import {intl} from '@qempo.io/web-common/utils/i18n';
import {Space, Input} from '@qempo.io/web-common/elements';
import {useActions} from '@qempo.io/web-common/redux';
import {emailRegex} from '@qempo.io/web-common/utils/string';
import {useForm, Controller} from 'react-hook-form';
import {actions as authActions} from '../../duck';
import {Link, useNavigate} from 'react-router-dom';
import {Checkbox} from '@qempo.io/web-common/elements';
import {usePrevious} from '@qempo.io/web-common';
import {User} from '../../../../entities';
import {SignUpHelmet} from './index.helmet';
import {AuthPage} from '../../components/AuthPage';

import style from '../auth.module.scss';
import buttonStyle from '@qempo.io/web-common/assets/styles/button.module.scss';

type Props = {
  signUpError?: ApiError;
  isSigningUp?: boolean;
  user?: User;
};

function SignUp(props: Props): JSX.Element {
  const {signUpError, isSigningUp, user} = props;

  const {signUp} = useActions({...authActions});

  const [agree, setAgree] = useState(false);

  const navigate = useNavigate();

  const prevSignUpError = usePrevious(signUpError);

  const {
    control,
    clearErrors,
    trigger,
    handleSubmit,
    watch,
    setError,
    formState: {errors},
  } = useForm({
    mode: 'onSubmit',
  });

  const password = watch('password');

  const errorInUseText = intl({id: 'auth.login.email.error.inUse'});

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user]);

  useEffect(() => {
    if (!prevSignUpError && signUpError) {
      if (signUpError.subErrors) {
        signUpError.subErrors.forEach((suberror) => {
          if (
            suberror.field === 'email' &&
            suberror.error === 'UserAlreadyExists'
          ) {
            setError('email', {
              message: errorInUseText,
            });
          }
        });
      }
    }
  }, [prevSignUpError, signUpError, errorInUseText]);

  const handleAgree = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) setAgree(true);
      else setAgree(false);
    },
    [setAgree]
  );

  const handleOnSubmit = (data: {[key: string]: string}) => {
    const {firstName, lastName, email, password: tPassword} = data;
    signUp({
      firstName,
      lastName,
      email,
      password: tPassword,
    });
  };

  const signUpErrors = {
    repeatPassword: intl({
      id: 'auth.signup.password.confirm.error.same',
    }),
    error: intl({id: 'auth.signup.error'}),
  };

  /*const handleClickFBLogin = useCallback(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (e: MouseEvent<HTMLButtonElement>) => {
      FBlogin().then((resp: FBLoginResponse) => {
        if (resp.authResponse) {
          loginFacebook({facebookToken: resp.authResponse.accessToken});
        }
      }, logger.error);
    },
    [logger, loginFacebook]
  );*/

  return (
    <>
      <SignUpHelmet />
      <AuthPage>
        <form
          method="POST"
          onSubmit={handleSubmit(handleOnSubmit)}
          className={style.formWrap}
        >
          <h2 className={style.title}>{intl({id: 'auth.signup.title'})}</h2>
          <Space multiplier={4} />
          <Controller
            name="firstName"
            control={control}
            defaultValue=""
            rules={{
              required: intl({
                id: 'auth.signup.firstName.error.required',
              }),
            }}
            render={({field}) => (
              <Input
                id={field.name}
                label={intl({id: 'auth.signup.firstName'})}
                name={field.name}
                onChange={field.onChange}
                onFocus={() => clearErrors(field.name)}
                value={field.value}
                error={errors[field.name]}
                disabled={isSigningUp}
              />
            )}
          />
          <Space multiplier={2} />
          <Controller
            name="lastName"
            control={control}
            defaultValue=""
            rules={{
              required: intl({
                id: 'auth.signup.lastName.error.required',
              }),
            }}
            render={({field}) => (
              <Input
                id={field.name}
                label={intl({id: 'auth.signup.lastName'})}
                name={field.name}
                onChange={field.onChange}
                onFocus={() => clearErrors(field.name)}
                value={field.value}
                error={errors[field.name]}
                disabled={isSigningUp}
              />
            )}
          />
          <Space multiplier={2} />
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{
              required: intl({id: 'auth.login.email.error.required'}),
              pattern: {
                value: emailRegex,
                message: intl({id: 'auth.login.email.error.format'}),
              },
            }}
            render={({field}) => (
              <Input
                id={field.name}
                label={intl({id: 'auth.login.email'})}
                name={field.name}
                onChange={field.onChange}
                onFocus={() => clearErrors(field.name)}
                onBlur={() => (field.value ? trigger(field.name) : undefined)}
                value={field.value}
                error={errors[field.name]}
                disabled={isSigningUp}
              />
            )}
          />
          <Space multiplier={2} />
          <Controller
            name="password"
            control={control}
            defaultValue=""
            rules={{
              required: intl({
                id: 'auth.login.password.error.required',
              }),
              minLength: {
                value: 8,
                message: intl({id: 'auth.login.password.error.min'}),
              },
            }}
            render={({field}) => (
              <Input
                id={field.name}
                label={intl({id: 'auth.login.password'})}
                name={field.name}
                onChange={field.onChange}
                onFocus={() => clearErrors(field.name)}
                value={field.value}
                error={errors[field.name]}
                type="password"
                disabled={isSigningUp}
              />
            )}
          />
          <Space multiplier={2} />
          <Controller
            name="repeatPassword"
            control={control}
            defaultValue=""
            rules={{
              required: intl({
                id: 'auth.signup.password.confirm.error.same',
              }),
              validate: (value: string) =>
                value === password || signUpErrors.repeatPassword,
            }}
            render={({field}) => (
              <Input
                id={field.name}
                label={intl({id: 'auth.signup.password.confirm'})}
                name={field.name}
                onChange={field.onChange}
                onFocus={() => clearErrors(field.name)}
                onBlur={() => (field.value ? trigger(field.name) : undefined)}
                value={field.value}
                error={errors[field.name]}
                type="password"
                disabled={isSigningUp}
              />
            )}
          />
          {signUpError && (
            <>
              <Space multiplier={3} />
              <p className={style.error}>{signUpErrors.error}</p>
            </>
          )}
          <Space multiplier={3} />
          <Checkbox
            id="agree"
            name="agree"
            value="true"
            checked={agree}
            onChange={handleAgree}
            disabled={isSigningUp}
          >
            <p className={style.terms}>
              {intl({id: 'auth.login.terms.agree.first', suffix: ' '})}
              <Link to="/terms-of-service" target="_blank">
                {intl({id: 'auth.login.terms.agree.terms'})}
              </Link>
              {intl({
                id: 'auth.login.terms.agree.and',
                suffix: ' ',
                prefix: ' ',
              })}
              <Link to="/privacy-policy" target="_blank">
                {intl({id: 'auth.login.terms.agree.privacy'})}
              </Link>
              {intl({id: 'auth.login.terms.agree.last', prefix: ' '})}
            </p>
          </Checkbox>
          <Space multiplier={3} />
          <button
            type="submit"
            disabled={!agree || isSigningUp}
            className={classNames(buttonStyle.btn, buttonStyle.blue)}
          >
            {intl({id: 'auth.signup.btn'})}
          </button>

          <Space multiplier={10} />
          <hr />
          <Space multiplier={2} />
          <Link
            to="/login"
            className={classNames(style.link, {
              [style.disabled]: isSigningUp,
            })}
          >
            {intl({id: 'auth.signup.login'})}
          </Link>
        </form>
      </AuthPage>
    </>
  );
}

SignUp.propTypes = {
  signUpError: PropTypes.object,
  isSigningUp: PropTypes.bool,
  user: PropTypes.object,
};

export default connect((state: RootState) => ({
  signUpError: state.auth.signUpError,
  isSigningUp: state.auth.isSigningUp,
  user: state.auth.user,
}))(SignUp);
