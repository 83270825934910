import React from 'react';
import PropTypes from 'prop-types';
import {
  Control,
  FieldErrors,
  UseFormClearErrors,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import {AddressForm} from '../../../address/components/AddressForm';

import style from './style.module.scss';

type Props = {
  control: Control;
  clearErrors: UseFormClearErrors<any>;
  setValue: UseFormSetValue<any>;
  trigger: UseFormTrigger<any>;
  watch: UseFormWatch<any>;
  errors: FieldErrors;
  disabled?: boolean;
  expandInputs?: boolean;
};

export function ShippingForm(props: Props) {
  const {
    control,
    clearErrors,
    setValue,
    trigger,
    watch,
    errors,
    disabled,
    expandInputs,
  } = props;

  return (
    <form className={style.shippingForm}>
      <AddressForm
        formNamePrefix="shippingAddress"
        country="PE"
        control={control}
        clearErrors={clearErrors}
        setValue={setValue}
        trigger={trigger}
        watch={watch}
        errors={errors.shippingAddress || {}}
        disabled={disabled}
        expandInputs={expandInputs}
      />
    </form>
  );
}

ShippingForm.propTypes = {
  control: PropTypes.object.isRequired,
  clearErrors: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  trigger: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  expandInputs: PropTypes.bool,
};
