import React, {ReactNode} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import style from './style.module.scss';

type Props = {
  singleRow?: boolean;
  children: ReactNode;
};

export function DualWrap({singleRow, children}: Props) {
  return (
    <div
      className={classNames(style.dualWrap, {[style.singleWrap]: singleRow})}
    >
      {children}
    </div>
  );
}

DualWrap.propTypes = {
  singleRow: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
