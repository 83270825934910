export default {
  en: {
    'orders.title': 'My orders',
    'orders.pending.title': 'Pending',
    'orders.completed.title': 'Delivered',
  },
  es: {
    'orders.title': 'Mis pedidos',
    'orders.pending.title': 'Pendientes',
    'orders.completed.title': 'Entregados',
  },
};
