import React, {Fragment} from 'react';
import classNames from 'classnames';
import {intls, Space} from '@qempo.io/web-common';
import {OrderState} from '../../duck';
import {useSelector} from 'react-redux';
import {RootState} from '../../../index';
import {useParams} from 'react-router';
import {ToggleMenu} from '../../../../elements/ToggleMenu';
import {LogisticPartnerProfile} from '../../components/LogisticPartnerProfile';
import {ShippingAddress} from '../../components/ShippingAddress';
import {useNormalizedState, useStateTranslation} from '../../hooks';
import {InvoiceSummary} from '../../components/InvoiceSummary';
import {OrderItem} from './components/OrderItem';
import {TitleBox} from '../../../../elements/TitleBox';
import {DualWrap} from '../../../../elements/DualWrap';

import style from './style.module.scss';

export default function Order() {
  const {orderId} = useParams();
  const {order, invoice} = useSelector<RootState, OrderState>((s) => s.order);

  const [stateIntl, infoIntl, travelerIntl, productIntl, shippingIntl] = intls([
    {id: 'order.state'},
    {id: 'order.menu.info'},
    {id: 'order.menu.info.traveler'},
    {id: 'order.menu.info.product'},
    {id: 'order.menu.info.shipping'},
    /*{id: 'order.menu.tracking'},
    {id: 'order.menu.chat'},*/
  ]);

  const normalizedState = useNormalizedState(order);
  const stateLabel = useStateTranslation(normalizedState);

  return (
    <div className={style.orderWrap}>
      <Space multiplier={1} />
      <h1>{orderId}</h1>
      <Space multiplier={1} />
      <div
        className={classNames(style.statusWrap, {
          [style.green]: normalizedState === 'COMPLETED',
          [style.yellow]: normalizedState !== 'COMPLETED',
        })}
      >
        <span>
          {stateIntl}: {stateLabel}
        </span>
      </div>
      <Space multiplier={3} />
      <div>
        <hr />
        <ToggleMenu noBorder noSeparator title={infoIntl} initialOpen>
          <TitleBox title={productIntl}>
            <div className={style.productsWrap}>
              {order &&
                order.items.map((i) => (
                  <Fragment key={i.id}>
                    <OrderItem orderItem={i} />
                    <hr />
                  </Fragment>
                ))}
              <Space multiplier={1} />
              <InvoiceSummary summary={invoice?.summary} />
              <Space multiplier={1} />
            </div>
          </TitleBox>
          <Space multiplier={3} />
          <DualWrap>
            <div>
              <TitleBox title={travelerIntl}>
                <LogisticPartnerProfile order={order} />
              </TitleBox>
            </div>
            <div>
              <TitleBox title={shippingIntl}>
                {order && (
                  <ShippingAddress shippingAddress={order.shippingAddress} />
                )}
              </TitleBox>
            </div>
          </DualWrap>
          <Space multiplier={3} />
        </ToggleMenu>
        <hr />
      </div>
    </div>
  );
}
