import React, {useEffect} from 'react';
import {
  Alert,
  AlertType,
  FloatingBottom,
  formatDate,
  intl,
  intls,
  Space,
  useActions,
} from '@qempo.io/web-common';
import {Card} from '../../../../elements/Card';
import {SubNavbar} from '../../../navbar/components/SubNavbar';
import {useCountdown} from '../../../../utils/countdown';
import {Icon} from '@qempo.io/web-common/elements';
import {ProfileMini} from '../../../../elements/ProfileMini';
import {useNavigate} from 'react-router-dom';
import {actions, LiteReserveState} from '../../duck';
import {useParams} from 'react-router';
import {useSelector} from 'react-redux';
import {RootState} from '../../../index';
import {ToggleMenu} from '../../../../elements/ToggleMenu';
import {useCheckout} from '../../../checkout/hooks';
import {Default, Mobile} from '../../../../utils/responsive';
import {useSession} from '../../../auth/hooks';
import {
  actions as checkoutActions,
  CheckoutState,
} from '../../../checkout/duck';
import Summary from '../../../order/components/Summary';
import {ShippingAddress} from '../../../order/components/ShippingAddress';

import style from './style.module.scss';

export default function LiteReservation() {
  const navigate = useNavigate();
  const {liteReserveId} = useParams();
  const {calculatePricing, fetchLiteReserve, resetState} = useActions(actions);
  const {checkOut, resetState: resetCheckoutState} =
    useActions(checkoutActions);

  const {
    liteReserve,
    isFetchingLiteReserve,
    fetchLiteReserveError,
    pricing,
    traveler,
  } = useSelector<RootState, LiteReserveState>((s) => s.liteReserve);
  const {isCheckingOut, order} = useSelector<RootState, CheckoutState>(
    (s) => s.checkout
  );
  const lang = useSelector<RootState, string>((s) => s.locale.lang);
  const user = useSession();

  const {hours, minutes} = useCountdown(
    liteReserve ? liteReserve.expiresAt : undefined,
    () => {
      navigate('/');
    }
  );

  useEffect(() => {
    if (order) {
      resetCheckoutState();
      navigate(`/order/${order.id}`);
    }
  }, [order]);

  const {
    selectPaymentMethod,
    content: checkoutContent,
    button,
  } = useCheckout({
    total: pricing ? pricing.total : undefined,
    currency: 'USD',
    name: user ? `${user.firstName} ${user.lastName}` : '',
    email: user ? user.email : '',
    disabled: !!isCheckingOut,
    lang,
    onComplete: (provider, processor, processorData) => {
      checkOut({
        liteReserveId,
        paymentMethod: provider,
        paymentProcessor: processor,
        processorData,
        country: 'PE',
      });
    },
    onError: () => {
      console.log('error');
    },
  });

  const [
    travelerIntl,
    productIntl,
    shippingLabelIntl,
    reservationIntl,
    validIntl,
  ] = intls([
    {id: 'lite.reserve.menu.traveler'},
    {id: 'lite.reserve.menu.product'},
    {id: 'lite.reserve.menu.shipping'},
    {
      id: 'lite.reservation.reservation',
      params: {
        traveler: traveler ? `${traveler.firstName} ${traveler.lastName}` : '',
      },
    },
    {
      id:
        hours === '00'
          ? 'lite.reservation.valid.minutes'
          : 'lite.reservation.valid.hours',
      params: {time: hours && minutes ? `${hours}:${minutes}` : ''},
    },
  ]);

  useEffect(() => {
    if (!liteReserve && !isFetchingLiteReserve && !fetchLiteReserveError) {
      fetchLiteReserve({liteReserveId});
    }
  }, [
    fetchLiteReserve,
    liteReserve,
    isFetchingLiteReserve,
    fetchLiteReserveError,
  ]);

  useEffect(() => {
    return () => {
      resetState();
    };
  }, [resetState]);

  useEffect(() => {
    if (liteReserve && liteReserve.flight.litePricingCommission) {
      calculatePricing({
        items: liteReserve.items.map((i) => ({
          categoryId: i.category.id,
          quantity: i.quantity,
          price: i.price,
        })),
        pricingId: liteReserve.flight.litePricingCommission.id,
      });
    }
  }, [liteReserve]);

  return (
    <>
      <SubNavbar />
      <div className={style.liteReservationWrap}>
        <Space multiplier={0.5} />

        <Card className={style.cardWrap}>
          <h1>{reservationIntl}</h1>
          <Space multiplier={3} />

          <div className={style.timerWrap}>
            <Icon icon="stopwatch" />
            <span>{validIntl}</span>
          </div>
          <Space multiplier={5} />

          <div className={style.contentWrap}>
            <div>
              <ToggleMenu title={travelerIntl} initialOpen>
                <Space multiplier={2} />
                {liteReserve && liteReserve.flight && (
                  <ProfileMini
                    travelerId={liteReserve.flight.travelerId}
                    subtitle1={formatDate(
                      new Date(liteReserve.flight.date),
                      'dd MMMM, yyyy',
                      lang
                    )}
                    subtitle2={`${liteReserve.flight.departureAdmin1.name} - ${liteReserve.flight.arrivalAdmin1.name}`}
                  />
                )}
                <Space multiplier={2} />
              </ToggleMenu>
              <Space multiplier={2} />
              <ToggleMenu title={shippingLabelIntl}>
                <Space multiplier={2} />
                {liteReserve && (
                  <ShippingAddress
                    shippingAddress={liteReserve.shippingAddress}
                  />
                )}
                <Space multiplier={2} />
              </ToggleMenu>
              <Space multiplier={2} />
              <ToggleMenu title={productIntl} initialOpen>
                <Space multiplier={1} />
                <Summary
                  items={
                    liteReserve
                      ? liteReserve.items.map((i) => ({
                          name: i.name,
                          quantity: i.quantity,
                          price: i.price,
                        }))
                      : undefined
                  }
                  summary={
                    pricing
                      ? {
                          commission: pricing.commission,
                          customs_duties: pricing.customsDuties,
                          qempo: pricing.qempo,
                          delivery: pricing.lastMile,
                          warranty: pricing.warranty,
                          total: pricing.total,
                        }
                      : undefined
                  }
                />
                <Space multiplier={3} />
              </ToggleMenu>
              <Space multiplier={3} />
            </div>
            <div>
              <Alert type={AlertType.WARNING}>
                {intl({id: 'lite.reservation.warning'})}
              </Alert>
              <Space multiplier={2} />
              <h3>{intl({id: 'lite.reservation.paymentMethod'})}</h3>
              <Space multiplier={2} />
              {selectPaymentMethod}
              {checkoutContent}
              <Space multiplier={4} />
              <div className={style.payButton}>
                <Default>{button}</Default>
              </div>
            </div>
          </div>
        </Card>
      </div>
      <Mobile>
        <FloatingBottom>{button}</FloatingBottom>
      </Mobile>
    </>
  );
}
