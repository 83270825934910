import {useEffect, useState} from 'react';

export type CountdownResult = {
  hours: string;
  minutes: string;
  seconds: string;
};

export function useCountdown(
  date?: Date | string,
  cb?: () => void
): Partial<CountdownResult> {
  const [countdown, setCountdown] = useState<Partial<CountdownResult>>({});

  useEffect(() => {
    if (date) {
      const dt = date instanceof Date ? date : new Date(date);

      if (dt.getTime() - Date.now() > 0) {
        const intervalId = setInterval(() => {
          const diff = dt.getTime() - Date.now();
          let cd: CountdownResult = {
            hours: '00',
            minutes: '00',
            seconds: '00',
          };

          if (diff > 0) {
            const s = Math.floor(diff / 1000);
            const m = Math.floor(s / 60);
            const h = Math.floor(m / 60);

            const seconds = (s % 60).toString(10).padStart(2, '0');
            const minutes = (m % 60).toString(10).padStart(2, '0');
            const hours = h.toString(10).padStart(2, '0');

            cd = {hours, minutes, seconds};
          } else {
            clearInterval(intervalId);
            if (cb) cb();
          }

          setCountdown(cd);
        }, 1000);

        return () => {
          clearInterval(intervalId);
        };
      } else {
        setCountdown({
          hours: '00',
          minutes: '00',
          seconds: '00',
        });
        if (cb) cb();
      }
    }
  }, [date]);

  return countdown;
}
