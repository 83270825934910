import React from 'react';
import {Helmet} from 'react-helmet-async';
import {intl} from '@qempo.io/web-common/utils/i18n';
import {useSelector} from 'react-redux';
import {RootState} from '../../../index';

export function LoginHelmet(): JSX.Element {
  const baseUrl = useSelector((state: RootState) => state.locale.baseUrl);
  const loginTitle = intl({id: 'auth.login.title'});

  return (
    <Helmet>
      <title>{intl({id: 'auth.login.title'})}</title>
      <meta property="og:title" content={loginTitle} />
      <meta name="twitter:title" content={loginTitle} />
      <link rel="canonical" href={`${baseUrl}login/`} />
    </Helmet>
  );
}
