export default {
  en: {
    'address.firstName': 'First name',
    'address.firstName.error.required': 'First name is required',
    'address.lastName': 'Last name',
    'address.lastName.error.required': 'Last name is required',
    'address.address1': 'Address 1',
    'address.address1.error.required': 'Address is required',
    'address.address2': 'Address 2',
    'address.admin1.PE': 'Department',
    'address.admin1.error.required.PE': 'Department is required',
    'address.admin1.US': 'State',
    'address.admin1.error.required.US': 'State is required',
    'address.admin2.PE': 'Province',
    'address.admin2.error.required.PE': 'Province is required',
    'address.admin2.US': 'City',
    'address.admin2.error.required.US': 'City is required',
    'address.admin3.PE': 'District',
    'address.admin3.error.required.PE': 'District is required',
    'address.zip': 'Zip code',
    'address.zip.error.required': 'Zip code is required',
    'address.idNumber.PE': 'DNI',
    'address.idNumber.error.required.PE': 'DNI is required',
    'address.phone': 'Phone',
    'address.phone.error.required': 'Phone is required',
    'address.phone.error.format': 'Phone is invalid',
  },
  es: {
    'address.firstName': 'Nombres',
    'address.firstName.error.required': 'Debes ingresar un nombre',
    'address.lastName': 'Apellidos',
    'address.lastName.error.required': 'Debes ingresar un apellido',
    'address.address1': 'Dirección',
    'address.address1.error.required': 'Debes ingresar una dirección',
    'address.address2': 'Referencia',
    'address.admin1.PE': 'Departamento',
    'address.admin1.error.required.PE': 'Debes ingresar un departamento',
    'address.admin1.US': 'Estado',
    'address.admin1.error.required.US': 'Debes ingresar un estado',
    'address.admin2.PE': 'Provincia',
    'address.admin2.error.required.PE': 'Debes ingresar una provincia',
    'address.admin2.US': 'Ciudad',
    'address.admin2.error.required.US': 'Debes ingresar una ciudad',
    'address.admin3.PE': 'Distrito',
    'address.admin3.error.required.PE': 'Debes ingresar un distrito',
    'address.zip': 'Código Postal',
    'address.zip.error.required': 'Debes ingresar un código postal',
    'address.idNumber.PE': 'DNI',
    'address.idNumber.error.required.PE': 'Debes ingresar un DNI',
    'address.phone': 'Teléfono',
    'address.phone.error.required': 'Debes ingresar un teléfono',
    'address.phone.error.format': 'Formato inválido',
  },
};
