import React from 'react';
import PropTypes from 'prop-types';
import {OrderItem} from '../../../../../../entities';
import {formatPricePrecision} from '../../../../../../utils/string';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormClearErrors,
  UseFormResetField,
  UseFormSetError,
} from 'react-hook-form';
import {intl} from '@qempo.io/web-common/utils/i18n';
import {Space, Input} from '@qempo.io/web-common/elements';
import UploadInvoice from '../UploadInvoice';
import {intls} from '@qempo.io/web-common';

import style from './style.module.scss';

type Props = {
  orderItem: OrderItem;
  control: Control;
  clearErrors: UseFormClearErrors<any>;
  resetField: UseFormResetField<any>;
  setError: UseFormSetError<any>;
  errors: FieldErrors;
  disabled?: boolean;
};

export default function RegisterItem(props: Props) {
  const {
    orderItem,
    control,
    clearErrors,
    resetField,
    setError,
    errors,
    disabled,
  } = props;

  const [
    orderNumberError,
    trackingError,
    invoiceFileError,
    invoiceFileTooLargeError,
  ] = intls([
    {
      id: 'order.item.tracking.order.error.required',
    },
    {
      id: 'order.item.tracking.tracking.error.required',
    },
    {
      id: 'order.item.tracking.upload.error.required',
    },
    {
      id: 'order.item.tracking.upload.error.large',
    },
  ]);

  return (
    <div className={style.registerItemWrap}>
      <div className={style.nameWrap}>
        <span className={style.name}>{orderItem.name}</span>
        <span>{formatPricePrecision(orderItem.price)}</span>
      </div>
      <Space multiplier={2} />
      <Controller
        name={`${orderItem.id}.orderItemId`}
        control={control}
        defaultValue={orderItem.id}
        render={({field}) => (
          <input
            type="hidden"
            id={field.name}
            name={field.name}
            value={field.value}
            disabled
          />
        )}
      />
      <Space multiplier={2} />
      <Controller
        name={`${orderItem.id}.orderNumber`}
        control={control}
        defaultValue={
          orderItem.itemTracking ? orderItem.itemTracking.orderNumber : ''
        }
        rules={{
          required: orderNumberError,
        }}
        render={({field}) => (
          <Input
            id={field.name}
            label={intl({id: 'order.item.tracking.order'})}
            name={field.name}
            onChange={field.onChange}
            onFocus={() => clearErrors(field.name)}
            value={field.value}
            error={errors[`${orderItem.id}`]?.orderNumber as {message: string}}
            disabled={disabled}
          />
        )}
      />
      <Space multiplier={2} />
      <Controller
        name={`${orderItem.id}.trackingNumber`}
        control={control}
        defaultValue={
          orderItem.itemTracking ? orderItem.itemTracking.trackingNumber : ''
        }
        rules={{
          required: trackingError,
        }}
        render={({field}) => (
          <Input
            id={field.name}
            label={intl({id: 'order.item.tracking.tracking'})}
            name={field.name}
            onChange={field.onChange}
            onFocus={() => clearErrors(field.name)}
            value={field.value}
            error={
              errors[`${orderItem.id}`]?.trackingNumber as {message: string}
            }
            disabled={disabled}
          />
        )}
      />
      <Space multiplier={2} />
      <Controller
        name={`${orderItem.id}.invoiceFile`}
        control={control}
        rules={{
          validate: (f) =>
            !orderItem.itemTracking?.invoiceFile && !f
              ? invoiceFileError
              : undefined,
        }}
        render={({field}) => (
          <>
            <UploadInvoice
              orderItem={orderItem}
              onChangeFile={(file) => {
                clearErrors(field.name);
                field.onChange(file);
              }}
              onErrorFile={(type) =>
                setError(field.name, {
                  type,
                  message: invoiceFileTooLargeError,
                })
              }
              onRemoveFile={() => {
                clearErrors(field.name);
                resetField(field.name);
              }}
              error={
                errors[`${orderItem.id}`]?.invoiceFile as {message: string}
              }
              disabled={disabled}
            />
            {errors[`${orderItem.id}`]?.invoiceFile && (
              <span className={style.errorLabel}>
                {
                  (errors[`${orderItem.id}`]?.invoiceFile as {message: string})
                    .message
                }
              </span>
            )}
          </>
        )}
      />
    </div>
  );
}

RegisterItem.propTypes = {
  orderItem: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,
  clearErrors: PropTypes.func.isRequired,
  resetField: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};
