import React from 'react';
import PropTypes from 'prop-types';
import {formatPricePrecision} from '../../../../utils/string';
import {Space} from '@qempo.io/web-common';
import {InvoiceSummary as InvoiceSummaryEntity} from '../../../../entities';
import {InvoiceSummary} from '../InvoiceSummary';

import style from './style.module.scss';

type Props = {
  items?: {name: string; quantity: number; price: string}[];
  summary?: InvoiceSummaryEntity;
};

export function SummaryNamePrice({name, price}: {name: string; price: string}) {
  return (
    <div className={style.nameWrap}>
      <p>{name}</p>
      <span className={style.price}>{formatPricePrecision(price)}</span>
    </div>
  );
}

export default function Summary(props: Props) {
  const {items, summary} = props;

  return (
    <div className={style.summaryWrap}>
      {items &&
        items.map((i, idx) => (
          <div key={`item-${idx}`} className={style.itemWrap}>
            <span className={style.text}>
              ( {i.quantity} ) {i.name}
            </span>
            <span>{formatPricePrecision(i.price)}</span>
          </div>
        ))}
      <Space multiplier={1} />
      {items && <hr />}
      <Space multiplier={1} />
      <InvoiceSummary summary={summary} />
    </div>
  );
}

Summary.propTypes = {
  items: PropTypes.array,
  summary: PropTypes.object,
};

SummaryNamePrice.propTypes = {
  name: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
};
