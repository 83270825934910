import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {GenericActionPayload} from '@qempo.io/web-common/redux';

import type {Country} from '../../entities';

export type LocaleState = {
  lang: string;
  country?: Country;
  domainCountry?: Country;
  baseUrl?: string;
};

export type LocaleAction = GenericActionPayload & {
  lang?: string;
  country?: Country;
  baseUrl?: string;
};

export const initialState: LocaleState = {
  lang: 'es',
};

const slice = createSlice({
  name: 'locale',
  initialState,
  reducers: {
    setLang: (s, {payload}: PayloadAction<LocaleAction>) => {
      s.lang = payload.lang as string;
    },
    setDomainCountry: (s, {payload}: PayloadAction<LocaleAction>) => {
      s.domainCountry = payload.country;
    },
    setBaseUrl: (s, {payload}: PayloadAction<LocaleAction>) => {
      s.baseUrl = payload.baseUrl;
    },
  },
});

export default slice.reducer;

export const {actions} = slice;
