import {ApiError} from '@qempo.io/web-common/http';
import {Invoice, Order} from '../../entities';
import {GenericAction, GenericActionPayload} from '@qempo.io/web-common/redux';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {combineEpics, Epic, ofType} from 'redux-observable';
import {catchError, map, switchMap} from 'rxjs/operators';
import {
  endpoints,
  getInvoiceApi,
  getOrderApi,
  registerItemsApi,
  RegisterItemApiRequest,
} from './api';
import {buildRoute} from '@qempo.io/web-common';
import {of} from 'rxjs';
import {RootState} from '../index';

export type OrderState = {
  order?: Order;
  isFetchingOrder?: boolean;
  fetchOrderError?: ApiError;
  invoice?: Invoice;
  isFetchingInvoice?: boolean;
  fetchInvoiceError?: ApiError;
  isRegisteringItems?: boolean;
  registerItemsSuccess?: boolean;
  registerItemsError?: ApiError;
};

export type OrderAction = GenericActionPayload & {
  orderId?: string;
  order?: Order;
  invoiceId?: string;
  invoice?: Invoice;
  registerItemsRequest?: RegisterItemApiRequest[];
  error?: ApiError;
};

export type OrderEpic = Epic<
  GenericAction<OrderAction>,
  GenericAction<OrderAction>,
  RootState
>;

export const initialState: OrderState = {};

const slice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchOrder: (s, action: PayloadAction<OrderAction>) => {
      s.isFetchingOrder = true;
    },
    fetchOrderSuccess: (s, {payload: {order}}: PayloadAction<OrderAction>) => {
      s.order = order;
      s.isFetchingOrder = false;
      s.fetchOrderError = undefined;
    },
    fetchOrderError: (s, {payload: {error}}: PayloadAction<OrderAction>) => {
      s.order = undefined;
      s.isFetchingOrder = false;
      s.fetchOrderError = error;
    },
    /*// eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchInvoice: (s, action: PayloadAction<OrderAction>) => {
      s.isFetchingInvoice = true;
    },*/
    fetchInvoiceSuccess: (
      s,
      {payload: {invoice}}: PayloadAction<OrderAction>
    ) => {
      s.invoice = invoice;
      s.isFetchingInvoice = false;
      s.fetchOrderError = undefined;
    },
    fetchInvoiceError: (s, {payload: {error}}: PayloadAction<OrderAction>) => {
      s.invoice = undefined;
      s.isFetchingInvoice = false;
      s.fetchOrderError = error;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    registerItems: (s, action: PayloadAction<OrderAction>) => {
      s.isRegisteringItems = true;
      s.registerItemsError = undefined;
    },
    registerItemsSuccess: (
      s,
      {payload: {order}}: PayloadAction<OrderAction>
    ) => {
      s.isRegisteringItems = false;
      s.order = order;
      s.registerItemsError = undefined;
      s.registerItemsSuccess = true;
    },
    registerItemsError: (s, {payload: {error}}: PayloadAction<OrderAction>) => {
      s.isRegisteringItems = false;
      s.registerItemsError = error;
    },
    resetState: () => initialState,
  },
});

export default slice.reducer;

const {
  fetchOrder,
  fetchOrderSuccess,
  fetchOrderError,
  fetchInvoiceSuccess,
  fetchInvoiceError,
  registerItems,
  registerItemsSuccess,
  registerItemsError,
  resetState,
} = slice.actions;

export const actions = {fetchOrder, registerItems, resetState};

const fetchOrderEpic: OrderEpic = (action$) =>
  action$.pipe(
    ofType(fetchOrder),
    switchMap(({payload: {orderId}}) =>
      getOrderApi(undefined, {
        route: buildRoute(endpoints.GET_ORDER, [orderId as string]),
      }).pipe(
        map((order) => fetchOrderSuccess({order})),
        catchError((error: ApiError) => of(fetchOrderError({error})))
      )
    )
  );

const fetchInvoiceEpic: OrderEpic = (action$) =>
  action$.pipe(
    ofType(fetchOrderSuccess),
    switchMap(({payload: {order}}) =>
      getInvoiceApi(undefined, {
        route: buildRoute(endpoints.GET_INVOICE, [(order as Order).invoiceId]),
      }).pipe(
        map((invoice) => fetchInvoiceSuccess({invoice})),
        catchError((error: ApiError) => of(fetchInvoiceError({error})))
      )
    )
  );

const registerItemsEpic: OrderEpic = (action$) =>
  action$.pipe(
    ofType(registerItems),
    switchMap(({payload: {orderId, registerItemsRequest}}) => {
      const fd = new FormData();

      if (registerItemsRequest && registerItemsRequest.length > 0) {
        registerItemsRequest.forEach((ir, idx) => {
          fd.append(`orderItemTracking[${idx}].orderItemId`, ir.orderItemId);
          fd.append(`orderItemTracking[${idx}].orderNumber`, ir.orderNumber);
          fd.append(
            `orderItemTracking[${idx}].trackingNumber`,
            ir.trackingNumber
          );
          if (ir.invoiceFile) {
            fd.append(`orderItemTracking[${idx}].invoiceFile`, ir.invoiceFile);
          }
        });
      }

      return registerItemsApi(fd, {
        route: buildRoute(endpoints.REGISTER_ITEMS, [orderId as string]),
      }).pipe(
        map((order) => registerItemsSuccess({order})),
        catchError((error: ApiError) => of(registerItemsError({error})))
      );
    })
  );

export const epics = combineEpics(
  fetchOrderEpic,
  fetchInvoiceEpic,
  registerItemsEpic
);
