import {getCreator} from '@qempo.io/web-common/http';
import environment from '../../environment';
import {Category} from '../../entities';

export const endpoints = Object.freeze({
  GET_CATEGORIES: `${environment.baseApiUrl}logistics/category`,
});

export const getCategoriesApi = getCreator<Category[]>(
  endpoints.GET_CATEGORIES
);
