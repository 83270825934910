import React from 'react';
import {Helmet} from 'react-helmet-async';
import {intl} from '@qempo.io/web-common/utils/i18n';
import {useSelector} from 'react-redux';
import {RootState} from './modules';
import environment from './environment';
import {removeTrailSlash} from '@qempo.io/web-common/utils';

import metaImg from './assets/images/meta-cover-lightblue.png';

export function AppHelmet(): JSX.Element {
  const baseUrl = useSelector((state: RootState) => state.locale.baseUrl);
  const baseUrlNoTrail = removeTrailSlash(baseUrl);

  const title = intl({id: 'qempo.document.title'});
  const description = intl({id: 'qempo.document.meta.og.description'});
  const ogTitle = intl({id: 'qempo.document.meta.og.title'});
  const ogDescription = intl({id: 'qempo.document.meta.og.description'});

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={ogTitle} />
      <meta property="og:description" content={ogDescription} />
      <meta property="og:image" content={`${baseUrlNoTrail}${metaImg}`} />
      <meta property="fb:app_id" content={environment.facebook} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@qempito" />
      <meta name="twitter:title" content={ogTitle} />
      <meta name="twitter:description" content={ogDescription} />
      <meta name="twitter:image" content={`${baseUrlNoTrail}${metaImg}`} />
      <link rel="canonical" href={`${baseUrl}`} />
    </Helmet>
  );
}
