import {getCreator, postCreator} from '@qempo.io/web-common/http';
import environment from '../../environment';
import {Order, PaymentMethods} from '../../entities';

export const endpoints = Object.freeze({
  GET_PAYMENT_METHODS: `${environment.baseApiUrl}logistics/checkout/method/list`,
  CHECKOUT_FROM_LITE_RESERVE: `${environment.baseApiUrl}logistics/checkout/execute/lite/:liteReserveId`,
});

export const getPaymentMethodsApi = getCreator<PaymentMethods>(
  endpoints.GET_PAYMENT_METHODS
);

export const checkoutFromLiteReserveApi = postCreator<Order>(
  endpoints.CHECKOUT_FROM_LITE_RESERVE
);
