import {CombinedState, combineReducers} from 'redux';
import {
  combineEpics,
  createEpicMiddleware,
  EpicMiddleware,
} from 'redux-observable';

import localeReducer, {
  initialState as localeInitialState,
  LocaleState,
} from './locale/duck';

import homeReducer, {
  epics as homeEpics,
  initialState as homeInitialState,
  HomeState,
} from './home/duck';

import authReducer, {
  epics as authEpics,
  initialState as authInitialState,
  AuthState,
} from './auth/duck';

import metaReducer, {
  epics as metaEpics,
  initialState as metaInitialState,
  MetaState,
} from './meta/duck';

import liteReserveReducer, {
  epics as liteReserveEpics,
  LiteReserveState,
  initialState as liteReserveInitialState,
} from './lite-reserve/duck';

import checkoutReducer, {
  epics as checkoutEpics,
  CheckoutState,
  initialState as checkoutInitialState,
} from './checkout/duck';

import orderReducer, {
  epics as orderEpics,
  OrderState,
  initialState as orderInitialState,
} from './order/duck';

import ordersReducer, {
  epics as ordersEpics,
  OrdersState,
  initialState as ordersInitialState,
} from './orders/duck';

export type RootState = {
  locale: LocaleState;
  home: HomeState;
  auth: AuthState;
  meta: MetaState;
  liteReserve: LiteReserveState;
  checkout: CheckoutState;
  order: OrderState;
  orders: OrdersState;
};

export const defaultInitialState = Object.freeze({
  locale: localeInitialState,
  home: homeInitialState,
  auth: authInitialState,
  meta: metaInitialState,
  liteReserve: liteReserveInitialState,
  checkout: checkoutInitialState,
  order: orderInitialState,
  orders: ordersInitialState,
});

export const rootEpic = combineEpics(
  homeEpics,
  authEpics,
  metaEpics,
  liteReserveEpics,
  checkoutEpics,
  orderEpics,
  ordersEpics
);

export const buildEpicMiddleware = (): EpicMiddleware<any> =>
  createEpicMiddleware();

export const appReducer = combineReducers({
  locale: localeReducer,
  home: homeReducer,
  auth: authReducer,
  meta: metaReducer,
  liteReserve: liteReserveReducer,
  checkout: checkoutReducer,
  order: orderReducer,
  orders: ordersReducer,
});

export const rootReducer = (
  state: RootState,
  action: {type: string}
): CombinedState<RootState> => {
  const tState = state;
  // NOTE: Do not forget to keep meta when logging out
  return appReducer(tState, action);
};
