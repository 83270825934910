export default {
  en: {
    'checkout.method': 'Payment method',
    'checkout.pay': 'Pay',
    'checkout.choose': 'Choose another payment method',
    'checkout.provider.paypal': 'Paypal',
    'checkout.provider.card': 'Credit/Debit card',
    'checkout.provider.transfer': 'Bank transfer',
    'checkout.provider.cash': 'Cash',
  },
  es: {
    'checkout.method': 'Método de pago',
    'checkout.pay': 'Pagar',
    'checkout.choose': 'Elegir otro método de pago',
    'checkout.provider.paypal': 'Paypal',
    'checkout.provider.card': 'Tarjeta de crédito/débito',
    'checkout.provider.transfer': 'Transferencia bancaria',
    'checkout.provider.cash': 'Cash',
  },
};
