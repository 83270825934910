export default {
  en: {
    'order.paid.title': 'Payment completed!',
    'order.paid.continue': 'Continue',
    'order.paid.register': 'Register purchase',
    'order.state': 'State',

    'order.register.title': 'Register purchase',
    'order.register.button': 'Registrar my purchase',
    'order.register.success': 'Tracking data registered',
    'order.register.error': 'There was an error during the registration',

    'order.state.created': 'Created',
    'order.state.paid': 'Paid',
    'order.state.purchased': 'Purchased',
    'order.state.received': 'Received',
    'order.state.arrived': 'Arrived',
    'order.state.delivered': 'Delivered',
    'order.state.completed': 'Completed',
    'order.state.canceled': 'Canceled',

    'order.item.quantity': 'Quantity',
    'order.item.tracking': 'Tracking',
    'order.item.tracking.add': 'Complete tracking',
    'order.item.tracking.order': 'Order number',
    'order.item.tracking.order.error.required': 'Order number is required',
    'order.item.tracking.tracking': 'Tracking number',
    'order.item.tracking.tracking.error.required': 'Tracking number is required',
    'order.item.tracking.upload': 'Upload invoice',
    'order.item.tracking.upload.error.required': 'Invoice file is required',
    'order.item.tracking.upload.error.large': 'Must be less than 6MB',

    'order.menu.info': 'Order information',
    'order.menu.info.traveler': 'Traveler information',
    'order.menu.info.product': 'Product information',
    'order.menu.info.shipping': 'Shipping information',
    'order.menu.tracking': 'Tracking',
    'order.menu.chat': 'Chat',
  },
  es: {
    'order.paid.title': '¡Pago completado!',
    'order.paid.continue': 'Continuar',
    'order.paid.register': 'Registrar mi compra',
    'order.state': 'Estado',

    'order.register.title': 'Registrar compra',
    'order.register.button': 'Registrar mi compra',
    'order.register.success': 'Compra registrada',
    'order.register.error': 'Hubo un error registrando su compra',

    'order.state.created': 'Creado',
    'order.state.paid': 'Pagado',
    'order.state.purchased': 'Comprado',
    'order.state.received': 'Recibido',
    'order.state.arrived': 'Llegó',
    'order.state.delivered': 'En ruta a dirección',
    'order.state.completed': 'Entregado',
    'order.state.canceled': 'Canceled',

    'order.item.quantity': 'Cantidad',
    'order.item.tracking': 'Tracking',
    'order.item.tracking.add': 'Completar tracking',
    'order.item.tracking.order': 'Número de pedido',
    'order.item.tracking.order.error.required': 'Deber colocar el número de pedido',
    'order.item.tracking.tracking': 'Tracking number',
    'order.item.tracking.tracking.error.required': 'Deber colocar el tracking number',
    'order.item.tracking.upload': 'Subir invoice',
    'order.item.tracking.upload.error.required': 'Debes subir un invoice de compra',
    'order.item.tracking.upload.error.large': 'Debe ser menor a 6MB',

    'order.menu.info': 'Información de pedido',
    'order.menu.info.traveler': 'Viajero',
    'order.menu.info.product': 'Producto(s)',
    'order.menu.info.shipping': 'Entrega',
    'order.menu.tracking': 'Seguimiento de pedido',
    'order.menu.chat': 'Chat con viajero',
  },
};
