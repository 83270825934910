import {GenericAction, GenericActionPayload} from '@qempo.io/web-common/redux';
import {Category} from '../../entities';
import {ApiError} from '@qempo.io/web-common/http';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {of} from 'rxjs';
import {combineEpics, Epic, ofType} from 'redux-observable';
import {catchError, map, switchMap} from 'rxjs/operators';
import {getCategoriesApi} from './api';
import {RootState} from '../index';

export type MetaState = {
  isFetchingCategories?: boolean;
  fetchCategoriesError?: ApiError;
  categories?: Category[];
};

export type MetaAction = GenericActionPayload & {
  categories?: Category[];
  error?: ApiError;
};

export type MetaEpic = Epic<
  GenericAction<MetaAction>,
  GenericAction<MetaAction>,
  RootState
>;

export const initialState: MetaState = {};

const slice = createSlice({
  name: 'meta',
  initialState,
  reducers: {
    getCategories: (s) => {
      s.isFetchingCategories = true;
      s.fetchCategoriesError = undefined;
    },
    getCategoriesSuccess: (s, {payload}: PayloadAction<MetaAction>) => {
      s.isFetchingCategories = false;
      s.categories = payload.categories;
      s.fetchCategoriesError = undefined;
    },
    getCategoriesError: (s, {payload}: PayloadAction<MetaAction>) => {
      s.isFetchingCategories = false;
      s.fetchCategoriesError = payload.error;
    },
  },
});

export default slice.reducer;

const {getCategories, getCategoriesSuccess, getCategoriesError} = slice.actions;

export const actions = {
  getCategories,
};

const getCategoriesEpic: MetaEpic = (action$) =>
  action$.pipe(
    ofType(getCategories),
    switchMap(() =>
      getCategoriesApi().pipe(
        map((categories) => getCategoriesSuccess({categories})),
        catchError((error: ApiError) => of(getCategoriesError({error})))
      )
    )
  );

export const epics = combineEpics(getCategoriesEpic);
