import React, {ReactNode, useState} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import style from './style.module.scss';
import {Icon} from '@qempo.io/web-common';

type Props = {
  initialOpen?: boolean;
  title: string;
  /*noTopBorder?: boolean;
  noBottomBorder?: boolean;*/
  noBorder?: boolean;
  noSeparator?: boolean;
  style?: {[key: string]: string};
  children: ReactNode;
};

export function ToggleMenu({
  title,
  initialOpen = false,
  /*noTopBorder = false,
  noBottomBorder = false,*/
  noBorder,
  noSeparator,
  style: styleProps,
  children,
}: Props) {
  const [open, setOpen] = useState<boolean>(initialOpen);
  return (
    <div
      style={styleProps}
      className={classNames(style.toggleMenuWrap, {[style.noBorder]: noBorder})}
    >
      {/*!noTopBorder && <hr />*/}
      <header onClick={() => setOpen(!open)}>
        <span>{title}</span>
        <Icon icon={open ? 'chevron-up' : 'chevron-down'} />
      </header>
      {open && !noSeparator && <hr />}
      <div className={classNames(style.childrenWrap, {[style.closed]: !open})}>
        {children}
      </div>
      {/*!noBottomBorder && <hr />*/}
    </div>
  );
}

ToggleMenu.propTypes = {
  initialOpen: PropTypes.bool,
  title: PropTypes.string.isRequired,
  noBorder: PropTypes.bool,
  noSeparator: PropTypes.bool,
  /*noTopBorder: PropTypes.bool,
  noBottomBorder: PropTypes.bool,*/
  style: PropTypes.object,
  children: PropTypes.node.isRequired,
};
