import {ApiError} from '@qempo.io/web-common/http';
import {Flight} from '../../entities';
import {GenericAction, GenericActionPayload} from '@qempo.io/web-common/redux';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {of} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {combineEpics, Epic, ofType} from 'redux-observable';
import {getFlightsApi} from './api';
import {RootState} from '../index';

export type HomeState = {
  isFetchingFlights?: boolean;
  fetchFlightsError?: ApiError;
  flights?: Flight[];
};

export type HomeAction = GenericActionPayload & {
  categoryId?: string;
  flights?: Flight[];
  error?: ApiError;
};

type HomeEpic = Epic<
  GenericAction<HomeAction>,
  GenericAction<HomeAction>,
  RootState
>;

export const initialState: HomeState = {};

const slice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchFlights: (s, _: PayloadAction<HomeAction>) => {
      s.isFetchingFlights = true;
      s.fetchFlightsError = undefined;
    },
    fetchFlightsSuccess: (s, {payload}: PayloadAction<HomeAction>) => {
      s.isFetchingFlights = undefined;
      s.fetchFlightsError = undefined;
      s.flights = payload.flights;
    },
    fetchFlightsError: (s, {payload: {error}}: PayloadAction<HomeAction>) => {
      s.isFetchingFlights = undefined;
      s.fetchFlightsError = error;
    },
  },
});

export default slice.reducer;

const {fetchFlights, fetchFlightsSuccess, fetchFlightsError} = slice.actions;

export const actions = {fetchFlights};

const fetchFlightsEpic: HomeEpic = (action$) =>
  action$.pipe(
    ofType(fetchFlights),
    switchMap(({payload: {categoryId: category_id}}) =>
      getFlightsApi(category_id ? {category_id} : undefined).pipe(
        map((flights: Flight[]) => fetchFlightsSuccess({flights})),
        catchError((error: ApiError) => of(fetchFlightsError({error})))
      )
    )
  );

export const epics = combineEpics(fetchFlightsEpic);
