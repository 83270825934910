import React, {ReactNode} from 'react';
import PropTypes from 'prop-types';

import style from './style.module.scss';

type Props = {
  title: string;
  children?: ReactNode;
};

export function TitleBox({title, children}: Props) {
  return (
    <div className={style.titleBoxWrap}>
      <div className={style.titleWrap}>
        <h4>{title}</h4>
      </div>
      <div className={style.contentWrap}>{children}</div>
    </div>
  );
}

TitleBox.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};
