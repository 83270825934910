import React from 'react';
import PropTypes from 'prop-types';
import {InvoiceSummary as InvoiceSummaryEntity} from '../../../../entities';
import {formatPricePrecision} from '../../../../utils/string';
import {intls} from '@qempo.io/web-common';

import style from './style.module.scss';

type Props = {summary?: InvoiceSummaryEntity};

export function InvoiceSummary(props: Props) {
  const {summary} = props;

  const [importationIntl, qempoIntl, lastMileIntl, totalIntl] = intls([
    {id: 'quote.import'},
    {id: 'quote.lastmile'},
    {id: 'quote.qempo'},
    {id: 'quote.total'},
  ]);

  return (
    <>
      <div className={style.itemWrap}>
        <span className={style.bold}>{importationIntl}</span>
        <span className={style.bold}>
          {summary && formatPricePrecision(summary.commission)}
          {!summary && `$ -.--`}
        </span>
      </div>
      <div className={style.itemWrap}>
        <span className={style.bold}>{qempoIntl}</span>
        <span className={style.bold}>
          {summary && formatPricePrecision(summary.qempo)}
          {!summary && `$ -.--`}
        </span>
      </div>
      <div className={style.itemWrap}>
        <span className={style.bold}>{lastMileIntl}</span>
        <span className={style.bold}>
          {summary && formatPricePrecision(summary.delivery)}
          {!summary && `$ -.--`}
        </span>
      </div>
      <div className={style.itemWrap}>
        <span className={style.bold}>
          <strong>{totalIntl}</strong>
        </span>
        <span className={style.bold}>
          <strong>
            {summary && formatPricePrecision(summary.total)}
            {!summary && `$ -.--`}
          </strong>
        </span>
      </div>
    </>
  );
}

InvoiceSummary.propTypes = {
  summary: PropTypes.object,
};
