import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {GenericActionPayload, ApiError, buildRoute} from '@qempo.io/web-common';
import {Flight, LiteReserve, Pricing, Traveler} from '../../entities';
import {of} from 'rxjs';
import {GenericAction} from '@qempo.io/web-common/redux';
import {combineEpics, ofType, Epic} from 'redux-observable';
import {catchError, map, switchMap} from 'rxjs/operators';
import {
  fetchPricingApi,
  fetchFlightApi,
  createLiteReserveApi,
  fetchLiteReserveApi,
  getTravelerApi,
  endpoints,
} from './api';
import {RootState} from '../index';

export type LiteReserveState = {
  isReserving?: boolean;
  reserveError?: ApiError;
  isFetchingPricing?: boolean;
  fetchPricingError?: ApiError;
  pricing?: Pricing;
  isFetchingFlight?: boolean;
  flight?: Flight;
  fetchFlightError?: ApiError;
  isFetchingLiteReserve?: boolean;
  liteReserve?: LiteReserve;
  fetchLiteReserveError?: ApiError;
  isFetchingTraveler?: boolean;
  traveler?: Traveler;
  fetchTravelerError?: ApiError;
};

export type LiteReserveAction = GenericActionPayload & {
  pricingId?: string;
  pricing?: Pricing;
  flightId?: string;
  flight?: Flight;
  liteReserveId?: string;
  liteReserve?: LiteReserve;
  traveler?: Traveler;
  error?: ApiError;
};

type LiteReserveEpic = Epic<
  GenericAction<LiteReserveAction>,
  GenericAction<LiteReserveAction>,
  RootState
>;

export const initialState: LiteReserveState = {};

const slice = createSlice({
  name: 'lite-reserve',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    createLiteReserve: (s, payload: PayloadAction<LiteReserveAction>) => {
      s.isReserving = true;
      s.liteReserve = undefined;
    },
    createLiteReserveSuccess: (
      s,
      {payload: {liteReserve}}: PayloadAction<LiteReserveAction>
    ) => {
      s.isReserving = false;
      s.liteReserve = liteReserve;
    },
    createLiteReserveError: (
      s,
      {payload: {error}}: PayloadAction<LiteReserveAction>
    ) => {
      s.isReserving = false;
      s.reserveError = error;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    calculatePricing: (s, {payload}: PayloadAction<LiteReserveAction>) => {
      s.isFetchingPricing = true;
      s.pricing = undefined;
    },
    calculatePricingSuccess: (
      s,
      {payload: {pricing}}: PayloadAction<LiteReserveAction>
    ) => {
      s.isFetchingPricing = false;
      s.pricing = pricing;
    },
    calculatePricingError: (
      s,
      {payload: {error}}: PayloadAction<LiteReserveAction>
    ) => {
      s.isFetchingPricing = false;
      s.fetchPricingError = error;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchFlight: (s, {payload}: PayloadAction<LiteReserveAction>) => {
      s.isFetchingFlight = true;
      s.flight = undefined;
    },
    fetchFlightSuccess: (
      s,
      {payload: {flight}}: PayloadAction<LiteReserveAction>
    ) => {
      s.isFetchingFlight = false;
      s.flight = flight;
    },
    fetchFlightError: (
      s,
      {payload: {error}}: PayloadAction<LiteReserveAction>
    ) => {
      s.isFetchingFlight = false;
      s.fetchFlightError = error;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchLiteReserve: (s, {payload}: PayloadAction<LiteReserveAction>) => {
      s.isFetchingLiteReserve = true;
      s.liteReserve = undefined;
      s.fetchLiteReserveError = undefined;
    },
    fetchLiteReserveSuccess: (
      s,
      {payload: {liteReserve}}: PayloadAction<LiteReserveAction>
    ) => {
      s.isFetchingLiteReserve = false;
      s.liteReserve = liteReserve;
      s.fetchLiteReserveError = undefined;
    },
    fetchLiteReserveError: (
      s,
      {payload: {error}}: PayloadAction<LiteReserveAction>
    ) => {
      s.isFetchingLiteReserve = false;
      s.fetchLiteReserveError = error;
    },
    fetchTraveler: (s) => {
      s.isFetchingTraveler;
    },
    fetchTravelerSuccess: (
      s,
      {payload: {traveler}}: PayloadAction<LiteReserveAction>
    ) => {
      s.traveler = traveler;
      s.isFetchingTraveler = false;
      s.fetchTravelerError = undefined;
    },
    fetchTravelerError: (
      s,
      {payload: {error}}: PayloadAction<LiteReserveAction>
    ) => {
      s.isFetchingTraveler = false;
      s.fetchTravelerError = error;
    },
    resetState: () => initialState,
  },
});

export default slice.reducer;

const {
  createLiteReserve,
  createLiteReserveSuccess,
  createLiteReserveError,
  calculatePricing,
  calculatePricingSuccess,
  calculatePricingError,
  fetchFlight,
  fetchFlightSuccess,
  fetchFlightError,
  fetchLiteReserve,
  fetchLiteReserveSuccess,
  fetchLiteReserveError,
  fetchTravelerSuccess,
  fetchTravelerError,
  resetState,
} = slice.actions;

export const actions = {
  createLiteReserve,
  calculatePricing,
  fetchFlight,
  fetchLiteReserve,
  resetState,
};

const createLiteReserveEpic: LiteReserveEpic = (action$) =>
  action$.pipe(
    ofType(createLiteReserve),
    switchMap(({payload: {createLiteReserveBody}}) =>
      createLiteReserveApi({...createLiteReserveBody}).pipe(
        map((liteReserve: LiteReserve) =>
          createLiteReserveSuccess({liteReserve})
        ),
        catchError((error: ApiError) => of(createLiteReserveError({error})))
      )
    )
  );

const fetchPricingEpic: LiteReserveEpic = (action$) =>
  action$.pipe(
    ofType(calculatePricing),
    switchMap(({payload: {pricingId, items}}) =>
      fetchPricingApi(
        {items},
        {
          route: buildRoute(endpoints.FETCH_PRICING, [pricingId as string]),
        }
      ).pipe(
        map((pricing: Pricing) => calculatePricingSuccess({pricing})),
        catchError((error: ApiError) => of(calculatePricingError({error})))
      )
    )
  );

const fetchFlightEpic: LiteReserveEpic = (action$) =>
  action$.pipe(
    ofType(fetchFlight),
    switchMap(({payload: {flightId}}) =>
      fetchFlightApi(undefined, {
        route: buildRoute(endpoints.FETCH_FLIGHT, [flightId as string]),
      }).pipe(
        map((flight: Flight) => fetchFlightSuccess({flight})),
        catchError((error: ApiError) => of(fetchFlightError({error})))
      )
    )
  );

const fetchLiteReserveEpic: LiteReserveEpic = (action$) =>
  action$.pipe(
    ofType(fetchLiteReserve),
    switchMap(({payload: {liteReserveId}}) =>
      fetchLiteReserveApi(undefined, {
        route: buildRoute(endpoints.FETCH_LITE_RESERVE, [
          liteReserveId as string,
        ]),
      }).pipe(
        map((liteReserve: LiteReserve) =>
          fetchLiteReserveSuccess({liteReserve})
        ),
        catchError((error: ApiError) => of(fetchLiteReserveError({error})))
      )
    )
  );

const fetchTravelerEpic: LiteReserveEpic = (action$) =>
  action$.pipe(
    ofType(fetchFlightSuccess, fetchLiteReserveSuccess as GenericActionPayload),
    switchMap(({payload: {flight, liteReserve}}) => {
      let flightId;
      if (flight) flightId = flight.travelerId;
      if (liteReserve) flightId = liteReserve.flight.travelerId;
      return getTravelerApi(undefined, {
        route: buildRoute(endpoints.GET_TRAVELER, [flightId as string]),
      }).pipe(
        map((traveler: Traveler) => fetchTravelerSuccess({traveler})),
        catchError((error: ApiError) => of(fetchTravelerError({error})))
      );
    })
  );

export const epics = combineEpics(
  createLiteReserveEpic,
  fetchPricingEpic,
  fetchFlightEpic,
  fetchLiteReserveEpic,
  fetchTravelerEpic
);
