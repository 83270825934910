import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Icon} from '@qempo.io/web-common/elements/Icon';

import style from './style.module.scss';
import defaultProfile from '../../assets/images/auth/default-profile.png';

type Props = {
  src?: string;
  alt?: string;
  className?: string;
  verified?: boolean;
};

export function Avatar(props: Props): JSX.Element {
  const {
    src = defaultProfile,
    alt = 'profile',
    className,
    verified = false,
  } = props;
  return (
    <div className={classNames(style.avatarWrap, className)}>
      <img src={src} alt={alt} />
      {verified && (
        <div className={style.verified}>
          <Icon icon="check" />
        </div>
      )}
    </div>
  );
}

Avatar.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
  verified: PropTypes.bool,
};
