import React, {MouseEvent, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {formatDate, Icon, Space} from '@qempo.io/web-common';
import {intl} from '@qempo.io/web-common/utils/i18n';
import {Link} from 'react-router-dom';
import {ProfileMini} from '../../../../elements/ProfileMini';
import {Flight} from '../../../../entities';
import {RootState} from '../../../index';
import {formatFormulaPrices} from '../../../../utils/string';

import style from './style.module.scss';

type Props = {
  categoryId?: string;
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
  flight: Flight;
  // traveler: Traveler;
};

export function TravelerProfileMini(props: Props) {
  const {categoryId, onClick, flight} = props;
  const lang = useSelector<RootState, string>((s) => s.locale.lang);

  const numOrders = intl({id: 'home.lite.travelers.profile.orders'});

  const commission = useMemo(() => {
    if (categoryId && flight.litePricingCommission) {
      const formula =
        flight.litePricingCommission.pricingCommissionFormulas.find(
          (f) => f.category.id === categoryId
        );
      if (formula) {
        return formatFormulaPrices(formula.formula);
      }
    }
  }, [categoryId, flight]);

  return (
    <Link
      to={`/lite/reserve/${flight.id}/${categoryId}`}
      className={style.travelerProfileMiniWrap}
      onClick={(e) => onClick && onClick(e)}
    >
      <ProfileMini
        travelerId={flight.travelerId}
        subtitle1={formatDate(new Date(flight.date), 'dd MMMM, yyyy', lang)}
        subtitle2={`${flight.departureAdmin1.name} - ${flight.arrivalAdmin1.name}`}
        // rating={4.9}
        bottomRight={commission && <p className={style.price}>{commission}</p>}
      />
      <Space multiplier={2} />
      <hr />
      <Space multiplier={2} />
      <div className={style.bottomWrap}>
        <div className={style.orders}>
          <Icon icon="box-open" size={20} />
          <span>345 {numOrders}</span>
        </div>
      </div>
    </Link>
  );
}

TravelerProfileMini.propTypes = {
  categoryId: PropTypes.string,
  onClick: PropTypes.func,
  flight: PropTypes.object.isRequired,
};
