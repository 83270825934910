import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import {setUpLogger} from './logger';
import BrowserApp from './BrowserApp';
import {initFAIcons} from '@qempo.io/web-common/elements/Icon';
import env from './environment';
import {FBinit} from './modules/auth/facebook';
import {
  faPencil,
  faStar,
  faBoxOpen,
  faArrowLeft,
  faChevronUp,
  faChevronDown,
  faStopwatch,
  faCheck,
  faCloudArrowUp,
  faXmark,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';

import './index.scss';

// Initializers
{
  FBinit();
  setUpLogger('Qempo Webapp', env.logLevel);
  initFAIcons([
    faPencil,
    faStar,
    faBoxOpen,
    faArrowLeft,
    faChevronUp,
    faChevronDown,
    faStopwatch,
    faCheck,
    faCloudArrowUp,
    faXmark,
    faWarning,
  ]);
}

// NOTE: __SSR__ is defined in the renderer.jsx as a hardcoded variable that indicates globally SSR
// eslint-disable-next-line no-underscore-dangle
/*if (window && (window as any).__SSR__) {
  ReactDOM.hydrate(BrowserApp, document.getElementById('root'));
} else {
  ReactDOM.render(BrowserApp, document.getElementById('root'));
}*/
ReactDOM.render(BrowserApp, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (['development', 'staging'].includes(env.env)) {
  // eslint-disable-next-line no-console
  reportWebVitals(console.log);
}
