export default {
  en: {
    'home.lite.title': 'Shop with Qempo traveler',
    'home.lite.subtitle': 'What do you want to import?',
    'home.lite.button.label': 'Choose the category',
    'home.lite.modal.title': 'Select the category',
    'home.lite.modal.button': 'Confirm',
    'home.lite.travelers.title': 'Available travelers',
    'home.lite.travelers.profile.orders': 'Orders',
  },
  es: {
    'home.lite.title': 'Comprar con viajeros de Qempo',
    'home.lite.subtitle': '¿Qué quieres importar?',
    'home.lite.button.label': 'Elige tu categoría',
    'home.lite.modal.title': 'Seleccionar categoría',
    'home.lite.modal.button': 'Confirmar',
    'home.lite.travelers.title': 'Viajeros disponibles',
    'home.lite.travelers.profile.orders': 'Entregas',
  },
};
